import { types } from '../actions/version';

interface VersionRequest {
  type: string;
  payload?: any;
}

type VersionActions = VersionRequest;

interface IVersionState {
  versions: IVersion[];
  version: IVersion | null;
  error: string | null;
  total: number;
}

interface IVersion {
  versionId: string
  verionNumber: string
  versionReleaseDate : Date
  versionReleaseNote: string
  versionRegUser : string
  versionRegDate : Date
}

const initialState: IVersionState = {
  versions: [],
  version: null,
  error: null,
  total: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: VersionActions) => {
  switch (type) {
    case types.CREATE_VERSION_SUCCESS:
      state.version = payload;
      return {
        ...state,
      };
    case types.SEARCH_VERSIONS_SUCCESS:
      state.versions = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.VERSION_DETAIL_SUCCESS:
      state.version = payload;
      return {
        ...state,
        pending: true,
      };
    case types.EDIT_VERSION_SUCCESS:
    case types.CANCEL_VERSION_SUCCESS:
    case types.RESET_CANCEL_VERSION_SUCCESS:
    {
      const index = state.versions.findIndex((item: any) => item.version === payload.version);
      if (index >= 0) {
        state.versions[index] = payload;
        state.version = payload;
      }
      return {
        ...state,
        pending: true,
      };
    }
    default:
      return state;
  }
};
