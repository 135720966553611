import { call, put, takeLatest } from 'redux-saga/effects';
import { getTrialSettingSuccess, saveTrialFlagSuccess, types } from '../actions/setting';
import { sagaPromise } from '../../lib/saga-promise';
import SettingServices from '../../services/setting';
import { changeLoading } from '../actions/app';

interface IAction {
  type: string;
  payload: any;
}

function* getTrialFlag() {
  try {
    const data: boolean = yield call(SettingServices.getTrialFlag);
    yield put(getTrialSettingSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* saveTrialFlag(action: IAction) {
  try {
    yield put(changeLoading(true));
    const data: boolean = yield call(SettingServices.saveTrialFlag, action.payload);
    yield put(saveTrialFlagSuccess(action.payload.turnOn));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* settingSaga() {
  yield takeLatest(types.GET_TRIAL_SETTING, sagaPromise(getTrialFlag));
  yield takeLatest(types.SAVE_TRIAL_SETTING, sagaPromise(saveTrialFlag));
}
