/* eslint-disable max-len */
import { useEffect, ChangeEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';
import { creatUser, editUser } from '../../state/actions/user';
import { start } from '../../lib/saga-promise';
import { USER_ROLE } from '../../constants/app';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import GroupSelector from '../../component/Form/GroupSelector';
import { getGroups } from '../../state/actions/group';
import RegisterForm from '../../component/Layout/RegisterForm';
import { RegisterInput, schemaEdit } from './types';
import TGTextField from '../../component/Elements/TGTextField';
import RoleSelector from '../../component/Form/RoleSelector';
import IsInvalidSwitch from '../../component/Form/IsInvalidSwitch';
import TGGrid from '../../component/Elements/TGGrid';
import CountrySelector from '../../component/Form/CountrySelector';
import { getCountries } from '../../state/actions/country';
import { groupPasswordPolicyDetail } from '../../state/actions/group_password_policy';
import { getRegExpSchemaPasswordPolicy } from '../../component/helpers/utility';

export default function MUserCreate(_userDetail: any = null) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isEditMode: boolean = Object.keys(_userDetail).length > 0;
  // const schema = isEditMode ? schemaEdit : schemaNew;
  const [schema, setSchema] = useState(yup.object());
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const countries = useSelector((state: RootStateOrAny) => state.country.allCountries);
  const detail = useSelector((state: RootStateOrAny) => state.user.user);
  const initialRender = useRef(true);
  const [method, setMethod] = useState<UseFormReturn<RegisterInput, object>>();
  const policyDetail = useSelector((state: RootStateOrAny) => state.group_password_policy.group_password_policy);

  useEffect(() => {
    start(getCountries, { noLoading: true }, dispatch);
    start(getGroups, { noLoading: true }, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRender.current && method) {
      // 自動採番されたユーザIDを表示する
      method.setValue('loginId', detail.loginId);
    } else {
      initialRender.current = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleChangePolicy('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, method]);

  useEffect(() => {
    const reg = getRegExpSchemaPasswordPolicy(
      policyDetail.isRequiredLowerAlphabetChars,
      policyDetail.isRequiredUpperAlphabetChars,
      policyDetail.isRequiredNumericChars,
      policyDetail.isRequiredMarkChars
    );
    let validateMsg = policyDetail.isRequiredLowerAlphabetChars ? t('PasswordLowerAlphabetChars') : '';
    validateMsg += policyDetail.isRequiredUpperAlphabetChars ? t('PasswordUpperAlphabetChars') : '';
    validateMsg += policyDetail.isRequiredNumericChars ? t('PasswordNumericChars') : '';
    validateMsg += policyDetail.isRequiredMarkChars ? t('PasswordMarkChars') : '';
    const schemaNew = schemaEdit.concat(
      yup.object({
        password: yup
          .string()
          .required('muserCreate.msg.requiredPassword')
          .min(
            policyDetail.minimumLength,
            t('Password107').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)
          )
          .max(127, 'muserCreate.msg.requiredPassword')
          .matches(reg, t('Password107').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)),
      })
    );
    setSchema(isEditMode ? schemaEdit : schemaNew);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDetail]);

  const handleSubmit = async (
    data: UnpackNestedValue<RegisterInput>,
    _method: UseFormReturn<RegisterInput, object>
  ) => {
    if (isEditMode) {
      const editData = {
        ...data,
        userId: _userDetail.userId,
        loginId: _userDetail.loginId,
      };
      await start(editUser, editData, dispatch);
    } else {
      data.loginId = '';
      await start(creatUser, data, dispatch);
      setMethod(_method);
    }
  };

  const changeRole = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: UseFormSetValue<RegisterInput>
  ) => {
    setValue('roleId', event.target.value);
    // システム管理者の場合はロールIDを初期化する
    if (event.target.value.toString() === roles.systemAdmin.toString()) {
      setValue('groupId', null);
    }
  };
  const handleChangePolicy = (groupId: string | undefined) => {
    start(groupPasswordPolicyDetail, { groupId }, dispatch);
  };
  const handleChangeGroup = (_groupId: string | undefined, setValue: UseFormSetValue<RegisterInput>) => {
    setValue('groupId', _groupId !== undefined ? _groupId : '');
    handleChangePolicy(_groupId);
  };

  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={t('muserCreate.label.top')}
      isEditMode={isEditMode}
      handleCancel={() => history(routes.muserSearch)}
      options={{
        defaultValues: {
          loginId: isEditMode ? _userDetail.loginId : t('muserCreate.placeHolder.UserId'),
          groupId: isEditMode ? _userDetail.groupId : null,
          roleId: isEditMode ? _userDetail.roleId : '',
          username: isEditMode ? _userDetail.username : '',
          email: isEditMode ? _userDetail.email : '',
          nickname: isEditMode ? _userDetail.nickname : '',
          countryId: isEditMode ? _userDetail.countryId : '',
        },
      }}
    >
      {({ register, setValue, formState: { errors }, watch }) => (
        <TGGrid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.userid')}
              isDisabled
              registration={register('loginId')}
              // isError={'loginId' in errors}
              // errorMessage={errors.loginId?.message}
            />
          </TGGrid>
          {!isEditMode && (
            <TGGrid item xs={12} sm={6}>
              <TGTextField
                label={t('common.label.password')}
                type="password"
                registration={register('password')}
                isError={'password' in errors}
                errorMessage={errors.password?.message}
              />
            </TGGrid>
          )}
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.username')}
              registration={register('username')}
              isError={'username' in errors}
              errorMessage={errors.username?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.email')}
              type="email"
              registration={register('email')}
              isError={'email' in errors}
              errorMessage={errors.email?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <RoleSelector
              roles={USER_ROLE}
              handleChange={(event) => changeRole(event, setValue)}
              roleId={watch('roleId')}
              isError={'roleId' in errors}
              errorMessage={errors.roleId?.message}
            />
          </TGGrid>

          {watch('roleId').toString() !== roles.systemAdmin.toString() && (
            <TGGrid item xs={12} sm={6}>
              <GroupSelector
                groups={groups}
                groupId={watch('groupId')}
                // handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
                handleChange={(data) => handleChangeGroup(data?.groupId, setValue)}
                error={'groupId' in errors}
                message={errors.groupId?.message}
              />
            </TGGrid>
          )}

          <TGGrid item xs={12} sm={6}>
            <CountrySelector
              countries={countries}
              countryId={watch('countryId')}
              handleChange={(data) => setValue('countryId', data?.countryId ?? '')}
              error={'countryId' in errors}
              message={errors.countryId?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.nickname')}
              registration={register('nickname')}
              isError={'nickname' in errors}
              errorMessage={errors.nickname?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <IsInvalidSwitch
              defaultChecked={!!(isEditMode && _userDetail.isInvalid)}
              registration={register('isInvalid')}
            />
          </TGGrid>
        </TGGrid>
      )}
    </RegisterForm>
  );
}
