import { useTranslation } from 'react-i18next';
import { FormGroup, Switch, FormControlLabel, Container, Button, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/Save';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import TGGrid from '../../component/Elements/TGGrid';
import MainCard from '../../component/maincard';
import { start } from '../../lib/saga-promise';
import { getTrialSetting, saveTrialFlag } from '../../state/actions/setting';

function TopRightButtons(handleSubmit: any) {
  // hooks
  const { t } = useTranslation();

  return (
    <Button
      onClick={handleSubmit}
      variant="contained"
      sx={{ my: 1, mx: 1 }}
      color="primary"
      startIcon={<DriveFileRenameOutlineOutlinedIcon />}
    >
      {t('common.btn.regist')}
    </Button>
  );
}

/**
 * Main component
 */
export default function EventTrial() {
  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // state
  const [successMsg, setSuccessMsg] = useState<string>('');

  const isTrialOn: boolean = useSelector((state: RootStateOrAny) => state.setting.isTrialOn);
  const [isTrialOnLocal, setTrialOn] = useState(isTrialOn);

  useEffect(() => {
    start(getTrialSetting, { noLoading: true }, dispatch);
  }, [dispatch]);

  const handleSubmit = async () => {
    setSuccessMsg(t(''));
    await start(saveTrialFlag, { turnOn: isTrialOnLocal }, dispatch);
    setSuccessMsg(t('common.msg.success'));
  };

  const labelSwitch: string = t('eventSetting.label.switchLabel');
  return (
    <Container disableGutters maxWidth="xl" component="main">
      <MainCard title={t('eventSetting.label.title')} secondary={TopRightButtons(handleSubmit)}>
        {successMsg !== '' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMsg}
          </Alert>
        )}
        <TGGrid container spacing={2}>
          <TGGrid item sm={12} md={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isTrialOnLocal} onChange={() => setTrialOn(!isTrialOnLocal)} />}
                label={labelSwitch}
              />
            </FormGroup>
          </TGGrid>
        </TGGrid>
      </MainCard>
    </Container>
  );
}
