import { useState } from 'react';
import '../../assets/scss/component/step-purchase.scss';

type Steps = {
    tabs: Array<{title: string}>;
    idx: number;
};

function StepPurchase({ tabs, idx }: Steps) {
  const [activeTab] = useState(idx);
  return (
    <div className="breadcrumb">
      {tabs.map((tab, index: number) => (
        <button
          key={tab.title}
          className={index === activeTab ? 'breadcrumb__step breadcrumb__step--active' : 'breadcrumb__step breadcrumb__step--disabled'}
          type="button"
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
}

export default StepPurchase;
