/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, Divider, Button, List, ListItem, ListItemText, Box, Checkbox } from '@mui/material';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import moment from 'moment';
import { start } from '../../../lib/saga-promise';
import { SearchLicenseRenew, SearchLicenseRenewDefault } from './types';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import TGGrid from '../../../component/Elements/TGGrid';
import StepPurchase from '../stepPurchase';
import { userDetail } from '../../../state/actions/user';
import { updateLicensesBank } from '../../../state/actions/license';
import DialogBankTransfer from '../DialogBankTransfer';

function ConfirmUpdateBank() {
  const param = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { handleSubmit } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codeError, setCodeError] = useState<string>('');
  const [updateBankLicenses, setupdateBankLicenses] = useState({
    infoForm: '',
    userId: '',
    totalAmount: '',
    startDate: '',
    endDate: '',
    listLicense: [],
    listLicenseNotBuy: []
  });
  useEffect(() => {
    if (localStorage.getItem('updateBankLicenses') != null) {
      const storedObjectString = localStorage.getItem('updateBankLicenses');
      if (storedObjectString !== null) {
        setupdateBankLicenses(JSON.parse(storedObjectString));
        start(userDetail, { userId: JSON.parse(storedObjectString).userId }, dispatch);
      }
    } else {
      window.location.href = '/purchase';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const detailUser = useSelector((state: RootStateOrAny) => state.user.user);

  const handleReturn: SubmitHandler<any> = async () => {
    window.location.href = `/purchase/updateBankTransfer/${localStorage.getItem('userId')}`;
  };
  const onSubmit: SubmitHandler<any> = async () => {
    try {
      // removeLocalStorage();
      const datasInput = {
        userId: param.userId,
        listLicense: updateBankLicenses.listLicense,
        listLicenseNotBuy: updateBankLicenses.listLicenseNotBuy,
      };
      const urlPayment: any = await start(updateLicensesBank, datasInput, dispatch);
      setCodeError('');
      if (typeof param.userId === 'string') {
        window.location.href = urlPayment;
      }
    // eslint-disable-next-line no-empty
    } catch (error: any) {
    }
  };

  const onSubmitBankTransfer: SubmitHandler<any> = async () => {
    try {
      // removeLocalStorage();
      const datasInput = {
        userId: param.userId,
        listLicense: updateBankLicenses.listLicense,
        listLicenseNotBuy: updateBankLicenses.listLicenseNotBuy,
        ModeSubscription: false,
      };
      const urlPayment: any = await start(updateLicensesBank, datasInput, dispatch);
      setCodeError('');
      window.location.href = urlPayment;
    // eslint-disable-next-line no-empty
    } catch (error: any) {
    }
  };


  const [open, setOpen] = useState(false);
  // mouse event in dialog
  const handleOpenDialog = () => {
    if (updateBankLicenses.infoForm === 'Trial') {
      handleSubmit(onSubmit)();
    } else {
      setOpen(true);
    }
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    setShowErrors(true);
    if (confirm) {
      handleSubmit(onSubmit)();
    } else {
      handleSubmit(onSubmitBankTransfer)();
    }
  };

  // event change language, turn off errors message
  const [, setShowErrors] = useState(true);
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleTextClick = () => {
    setIsChecked(!isChecked);
  };

  const tabs = [
    {
      title: t('purchaseStepBuyLicense'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];
  return (
    <div className="purchase__container">
      <PurchaseForm>
        <StepPurchase tabs={tabs} idx={1} />
        <TGGrid container columns={12} mt={8}>
          <TGGrid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3">{t('purchase.title.confirmInfo')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body1">{t('purchase.notes.confirmInfo.description')}</Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid>
          {detailUser.roleId === 2 && (
            <>
              <TGGrid item xs={12} mt={3} mb={1}>
                <Typography variant="h4">{t('purchaseUserInformation2')}</Typography>
              </TGGrid>
              <Divider sx={{ borderBottom: '2px solid' }} />
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {t('purchaseCompanyName')}
                  </Typography>
                </TGGrid>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {detailUser.groupName}
                  </Typography>
                </TGGrid>
              </TGGrid>
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {t('purchaseApplicationDepartment')}
                  </Typography>
                </TGGrid>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {detailUser.department}
                  </Typography>
                </TGGrid>
              </TGGrid>
            </>
          )}
          <TGGrid item xs={12} mt={3} mb={1}>
            <Typography variant="h4">
              {detailUser.roleId === 2 ? t('purchaseBuyerInformation.success.label.corporateAdminInfo') : t('purchaseUserInformation3')}
            </Typography>
          </TGGrid>
          <Divider sx={{ borderBottom: '2px solid' }} />
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('purchaseBuyerInformation.success.label.fullName')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.username}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.email')}
              </Typography>
            </TGGrid>
            <TGGrid item md={5} xs={8}>
              <Typography
                variant="body1"
                component="a"
                href={`mailto:${detailUser.email}`}
                className="fs-09-rem"
              >
                {detailUser.email}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.postcode')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.roleId !== 2 ? detailUser.postcode : detailUser.postcodeGroup}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.address')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.roleId !== 2 ? detailUser.address : detailUser.addressGroup}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.phoneNumber')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.roleId !== 2 ? detailUser.phoneNumber : detailUser.phoneNumberGroup}
              </Typography>
            </TGGrid>
          </TGGrid>
        </TGGrid>
        <TGGrid item xs={12} mt={3} mb={1}>
          <Typography variant="h4">{t('registerContent')}</Typography>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        {updateBankLicenses.infoForm !== 'Trial' && (
          <>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('common.label.endDate')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8} mt={3}>
                <Typography variant="body1" className="fs-09-rem">
                  {moment(updateBankLicenses.endDate).add(1, 'years').format('YYYY/MM/DD')}
                </Typography>
                <List>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('confirmEnddateTrialEx1')} />
                  </ListItem>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('confirmEnddateTrialEx2')} />
                  </ListItem>
                </List>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('deliveryTime')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={1}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('deliveryTimeEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('additionForSalesPrice')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('additionForSalesPriceEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('licensesBuy')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {updateBankLicenses.listLicense.length}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('LicenseUnitPrice')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('8,800')}
                  &nbsp;
                  {t('YenTaxIncluded')}
                </Typography>
                <List>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('LicenseUnitPriceTrialEx2')} />
                  </ListItem>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('LicenseUnitPriceEx')} />
                  </ListItem>
                </List>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('TotalAmount')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {updateBankLicenses.totalAmount}
                  {' '}
&nbsp;
                  {t('YenTaxIncluded')}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* 修正スタート箇所 */}
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('PaymentMethods')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardCompany')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardCompanyEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankTransferDetails')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankTransferDetailsEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* 修正終了箇所 */}
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('PaymentDeadline')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('RegardingCancellationTermination')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ProductExchangeReturn')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ProductExchangeReturnEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('Cancellation')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CancellationEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('RegardingContractExtension')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtension')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtensionEx1')}
                </Typography>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtensionEx2')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('userImport.label.csvPName')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
          </>
        )}
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            {updateBankLicenses.infoForm !== 'Trial' && (
              <Typography variant="body1" className="fs-09-rem">
                {t('userImport.label.csvPName')}
              </Typography>
            )}
          </TGGrid>
          <TGGrid item md={9} xs={8}>
            <Typography mt={4} display="flex" alignItems="center" onClick={handleTextClick}>
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              {t('noteContractEx')}
            </Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} xs={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleReturn}
              >
                {t('returnButton')}
              </Button>
              <Button
                variant="contained"
                disabled={!isChecked}
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                // onClick={handleFormSubmit}
                onClick={handleOpenDialog}
              >
                {t('purchase.button.next')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
      <DialogBankTransfer open={open} onClose={handleCloseDialog} onSubscription={handleConfirmation} />
    </div>
  );
}

export default ConfirmUpdateBank;
