/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { start } from '../../lib/saga-promise';
import { versionDetail } from '../../state/actions/version';
import VersionCreate from './create';

export default function VersionEdit() {
  const dispatch = useDispatch();
  const param = useParams();
  const detail = useSelector((state: RootStateOrAny) => state.version.version);
  useEffect(() => {
    if (param.id) {
      start(versionDetail, { versionId: param.id }, dispatch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {detail !== null && detail.versionId === param.id && <VersionCreate {...detail} />}
    </>
  );
}
