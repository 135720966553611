/* eslint-disable react/require-default-props */
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';

type TGTextFieldProps = TextareaAutosizeProps & {
  registration?: Partial<UseFormRegisterReturn>;
  disabled: boolean;
  minRows: number;
};

export default function TGTextarea({ registration, disabled, minRows, ...props }: TGTextFieldProps) {
  return (
    <TextareaAutosize
      {...registration}
      {...props}
      disabled={disabled}
      style={{ width: '100%', padding: 5 }}
      minRows={minRows}
    />
  );
}
