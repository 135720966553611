/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import PurchaseService from '../../services/purchase';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

import {
  types,
  inputEmailSuccess,
  GetInformationTrialSuccess,
  searchInformationBuyerSuccess,
  InfoBuyerSuccess,
} from '../actions/purchase';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Iaction {
  type: string;
  payload: any;
}

function* inputEmail(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.sendEmail, action.payload);
    yield put(inputEmailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* inputInformationBuyer(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.inputInformationBuyer, action.payload);
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* inputInformationTrial(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.inputInformationTrial, action.payload);
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* GetInformationTrial(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.infoTrialSuccess, action.payload);
    yield put(GetInformationTrialSuccess(data));
    yield put(changeLoading(false));
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}
function* getInformationBuyer(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.getInformationBuyer, action.payload);
    yield put(searchInformationBuyerSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* inputInfoBuyerSuccess(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.inputInfoBuyerSuccess, action.payload);
    if (!data.status) {
      yield put(InfoBuyerSuccess(data));
      yield put(changeLoading(false));
    }
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* buyAdditionalLicenses(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.buyAdditionalLicenses, action.payload);
    yield put(InfoBuyerSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

// function* buyAdditionalLicensesSuccess(action: Iaction) {
//   try {
//     yield put(changeLoading(true));
//     const data: ResponseGenerator = yield call(PurchaseService.buyAdditionalLicensesSuccess, action.payload);
//     // yield put(InputInfoBuyerAddLicenseSuccess2(data));
//     yield put(changeLoading(false));
//     return data;
//   } catch (error) {
//     yield put(changeLoading(false));
//     throw error;
//   }
// }

function* CheckIsReadInfo(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(PurchaseService.CheckIsReadInfo, action.payload);
    if (!data.status) {
      yield put(changeLoading(false));
    }
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* purchaseSaga() {
  yield takeLatest(types.INPUT_EMAIL, sagaPromise(inputEmail));
  yield takeLatest(types.SEARCH_INFORMATION_BUYER, sagaPromise(getInformationBuyer));
  yield takeLatest(types.INPUT_INFORMATION_BUYER, sagaPromise(inputInformationBuyer));
  yield takeLatest(types.INPUT_INFORMATION_TRAIL, sagaPromise(inputInformationTrial));
  yield takeLatest(types.GET_INFORMATION_TRAIL, sagaPromise(GetInformationTrial));
  yield takeLatest(types.INPUT_INFO_BUYER_SUCCESS, sagaPromise(inputInfoBuyerSuccess));
  yield takeLatest(types.BUY_ADDITIONAL_NORMAL, sagaPromise(buyAdditionalLicenses));
  // yield takeLatest(types.INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS, sagaPromise(buyAdditionalLicensesSuccess));
  yield takeLatest(types.CHECK_IS_READ, sagaPromise(CheckIsReadInfo));
}
