import { all } from 'redux-saga/effects';

import authSaga from './auth';
import purchaseSaga from './purchase';
import userSaga from './user';
import groupSaga from './group';
import licenseSaga from './license';
import countrySaga from './country';
import deviceSaga from './device';
import versionSaga from './version';
import groupPasswordPolicySaga from './group_password_policy';
import userinfoReadMessageSaga from './userinfo_read_message';
import settingSaga from './setting';

export default function* rootSaga() {
  yield all([
    authSaga(),
    purchaseSaga(),
    userSaga(),
    groupSaga(),
    licenseSaga(),
    countrySaga(),
    deviceSaga(),
    versionSaga(),
    groupPasswordPolicySaga(),
    userinfoReadMessageSaga(),
    settingSaga(),
  ]);
}
