import { types } from '../actions/purchase';

interface purchaseRequest {
  type: string;
  payload?: any;
}

type PurchaseActions = purchaseRequest;

interface IPurchaseState {
  buyer: any;
  info: any;
  error: string | null;
  total: number;
}

const initialState: IPurchaseState = {
  buyer: [],
  info: [],
  error: null,
  total: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: PurchaseActions) => {
  switch (type) {
    case types.SEARCH_INFORMATION_BUYER_SUCCESS:
      state.buyer = payload;
      return {
        ...state,
      };
    case types.INFO_BUYER_SUCCESS:
      state.info = payload;
      return {
        ...state,
      };
    // case types.INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS2:
    //   state.buyer = payload;
    //   return {
    //     ...state,
    //   };
    case types.GET_INFORMATION_TRAIL_SUCCESS:
      state.info = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};
