/* eslint-disable no-case-declarations */
import { types } from '../actions/user';

interface UserRequest {
  type: string;
  payload?: any;
}

type UserActions = UserRequest;

interface UserState {
  pending: boolean;
  user: any;
  error: string | null;
  musers: any;
  canBindUsers: any;
  users: any;
  total: number;
}

const initialState: UserState = {
  pending: false,
  user: [],
  error: null,
  musers: [],
  canBindUsers: [],
  users: [],
  total: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: UserActions) => {
  switch (type) {
    case types.CREATE_USER_SUCCESS:
      state.user = payload;
      return {
        ...state,
        pending: true,
      };
    case types.SEARCH_USER_SUCCESS:
      state.users = payload.list;
      state.total = payload.count;
      return {
        ...state,
        pending: true,
      };
    case types.SEARCH_MUSER_SUCCESS:
      state.musers = payload.list;
      state.total = payload.count;
      return {
        ...state,
        pending: true,
      };
    case types.USER_DETAIL_SUCCESS:
      state.user = payload;
      return {
        ...state,
        pending: true,
      };
    case types.CAN_LICENSE_BIND_SUCCESS:
      state.canBindUsers = payload;
      return {
        ...state,
        pending: true,
      };
    case types.EDIT_USER_SUCCESS:
      state.user = payload;
      return {
        ...state,
        pending: true,
      };
    case types.PASSWORD_RESET_SUCCESS:
    case types.PASSWORD_CHANGE_SUCCESS:
    case types.PASSWORD_EXPIRED_CHANGE_SUCCESS:
    case types.UPDATE_USER_LICENSE_SUCCESS:
      let index = state.musers.findIndex((item: any) => item.userId === payload.userId);
      if (index >= 0) {
        state.musers[index] = payload;
        state.user = payload;
      }
      index = state.users.findIndex((item: any) => item.userId === payload.userId);
      if (index >= 0) {
        state.users[index] = payload;
        state.user = payload;
      }
      return {
        ...state,
        pending: true,
      };
    default:
      return {
        ...state,
      };
  }
};
