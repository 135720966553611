/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Theme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useEffect } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, useForm, } from 'react-hook-form';
import moment from 'moment';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../constants/app';
import { getSUserInfoReadMessage } from '../../state/actions/userinfo_read_message';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import { exportCsv } from '../../component/helpers/utility';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import TGTextField from '../../component/Elements/TGTextField';
import DownloadButton from '../../component/Form/DownloadButton';
import ReadSelector from '../../component/Form/ReadSelector';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput, } from './userinfo_type';
import TGGrid from '../../component/Elements/TGGrid';
import OverHideText from '../../component/Elements/OverHideText';

const localStorageKey = 'getSUserInfoReadMessage';

interface MainProps {
  theme: Theme;
}
export default function UserInfoReadMessageGet() {
  const StyledTableCell = styled('div')<MainProps>(() => ({
    fontSize: 14,
    backgroundColor: '#FF7C80',
    display: 'grid',
    textAlign: 'center',
  }));
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });
  const rows = useSelector((state: RootStateOrAny) => state.version);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const ReadStatus = [
    {
      value: 'Read',
      label: 'common.label.read',
    },
    {
      value: 'Unread',
      label: 'common.label.unread',
    },
  ];
  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    setValue('GroupId', user.groupId);
    handleSubmit(searchSubmit)();
  };
  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(getSUserInfoReadMessage, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/userinfo/export?ReadDateFrom=${encodeURIComponent(data.ReadDateFrom)}&ReadDateTo=${encodeURIComponent(data.ReadDateTo)}&ReadStatus=${encodeURIComponent(data.ReadStatus)}&UserId=${encodeURIComponent(data.UserId)}&GroupId=${encodeURIComponent(user.groupId)}&locale=${sessionStorage.getItem('locale') === 'cn' ? 'zh-Hans' : sessionStorage.getItem('locale')}`;
    exportCsv(url, 'userInfoReadmessage');
  };

  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchForm title={t('userInfoReadMessage.label.top')} description={t('userInfoReadMessage.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin, roles.groupAdmin]}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={3}>
            <TGTextField
              label={t('userInfoReadMessage.label.readDateFrom')}
              type="date"
              registration={register('ReadDateFrom')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField
              label={t('userInfoReadMessage.label.readDateTo')}
              type="date"
              registration={register('ReadDateTo')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <ReadSelector
              Reads={ReadStatus}
              handleChange={(event) => setValue('ReadStatus', event.target.value)}
              ReadStatus={watch('ReadStatus')}
              hasBlank
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('UserId')} label={t('common.label.userid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid
        item
        xs={12}
        sx={{ float: 'right', mb: 2 }}
        userRoleId={user.roleId}
        showRoles={[roles.systemAdmin, roles.groupAdmin]}
      >
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.versions}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'userInfoId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'userID':
                return `${row.userId}`;
              case 'titleJaVer':
                return `${row.titleJaVer}`;
              case 'ReadDate':
                if (row.readDate != null) {
                  return `${moment(row.readDate).format('YYYY/MM/DD hh:mm')}`;
                }
                return '';
              case 'roleId':
                if (row.roleId === 3) {
                  return `${t('common.label.role3')}`;
                }
                if (row.roleId === 2) {
                  return `${t('common.label.role2')}`;
                }
                return `${t('common.label.role1')}`;
              case 'UserName':
                return `${row.username}`;
              case 'ReadStatus':
                if (row.readStatus === 'Unread') {
                  return <StyledTableCell theme={theme}>{t('common.label.unread')}</StyledTableCell>;
                }
                return `${t('common.label.read')}`;
              default:
                return <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
