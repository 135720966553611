import moment from 'moment';
import { DEFAULT_PAGE } from '../../../constants/app';
import { TableColumn } from '../../../types';

export type ColumnBuyLicense = TableColumn & {
  id: 'licenseId' | 'licenseCode' | 'availableDeviceNumber' | 'endDate' | 'UserNameWithLoginId' | 'isRenew';
};
export type ColumnUpDateStopLicense = TableColumn & {
  id: 'licenseId' | 'licenseCode' | 'availableDeviceNumber' | 'endDate' | 'UserNameWithLoginId' | 'isStop';
};
export type ColumnReopenLicense = TableColumn & {
  id: 'licenseId' | 'licenseCode' | 'availableDeviceNumber' | 'endDate' | 'UserNameWithLoginId' | 'isReopen';
};
export const columns: ColumnBuyLicense[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
    align: 'center',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'UserNameWithLoginId',
    label: 'common.label.username',
    align: 'center',
  },
  {
    id: 'isRenew',
    label: 'purchase.title.checkboxBuy',
    align: 'center',
  },
];

export const columns2: ColumnUpDateStopLicense[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'UserNameWithLoginId',
    label: 'common.label.username',
  },
  {
    id: 'isStop',
    label: 'updateStop',
    align: 'center',
  },
];
export const columns3: ColumnReopenLicense[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'UserNameWithLoginId',
    label: 'common.label.username',
  },
  {
    id: 'isReopen',
    label: 'reopenLabel',
    align: 'center',
  },
];

export type SearchLicenseRenew = {
  pageIndex: number;
};

export const SearchLicenseRenewDefault: SearchLicenseRenew = {
  pageIndex: DEFAULT_PAGE,
};
