export const types = {
  INPUT_EMAIL: 'INPUT_EMAIL',
  INPUT_EMAIL_SUCCESS: 'INPUT_EMAIL_SUCCESS',

  SEARCH_INFORMATION_BUYER: 'SEARCH_INFORMATION_BUYER',
  SEARCH_INFORMATION_BUYER_SUCCESS: 'SEARCH_INFORMATION_BUYER_SUCCESS',
  INPUT_INFORMATION_BUYER: 'INPUT_INFORMATION_BUYER',

  INPUT_INFORMATION_TRAIL: 'INPUT_INFORMATION_TRAIL',
  INPUT_INFORMATION_TRAIL_SUCCESS: 'INPUT_INFORMATION_TRAIL_SUCCESS',

  GET_INFORMATION_TRAIL: 'GET_INFORMATION_TRAIL',
  GET_INFORMATION_TRAIL_SUCCESS: 'GET_INFORMATION_TRAIL_SUCCESS',

  INPUT_INFO_BUYER_SUCCESS: 'INPUT_INFO_BUYER_SUCCESS',
  INFO_BUYER_SUCCESS: 'INFO_BUYER_SUCCESS',
  INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS: 'INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS',
  BUY_ADDITIONAL_NORMAL: 'BUY_ADDITIONAL_NORMAL',
  INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS2: 'INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS2',

  CHECK_IS_READ: 'CHECK_IS_READ',
};

export const inputEmail = (payload: any) => ({
  type: types.INPUT_EMAIL,
  payload,
});

export const inputEmailSuccess = (payload: any) => ({
  type: types.INPUT_EMAIL_SUCCESS,
  payload,
});

export const searchInformationBuyer = (payload: any) => ({
  type: types.SEARCH_INFORMATION_BUYER,
  payload,
});

export const searchInformationBuyerSuccess = (payload: any) => ({
  type: types.SEARCH_INFORMATION_BUYER_SUCCESS,
  payload,
});

export const inputInformationBuyer = (payload: any) => ({
  type: types.INPUT_INFORMATION_BUYER,
  payload,
});
export const buyAdditionalLicenses = (payload: any) => ({
  type: types.BUY_ADDITIONAL_NORMAL,
  payload,
});

export const inputInformationTrial = (payload: any) => ({
  type: types.INPUT_INFORMATION_TRAIL,
  payload,
});

export const inputInformationTrialSuccess = (payload: any) => ({
  type: types.INPUT_INFORMATION_TRAIL_SUCCESS,
  payload,
});

export const GetInformationTrial = (payload: any) => ({
  type: types.GET_INFORMATION_TRAIL,
  payload,
});

export const GetInformationTrialSuccess = (payload: any) => ({
  type: types.GET_INFORMATION_TRAIL_SUCCESS,
  payload,
});

export const InputInfoBuyerSuccess = (payload: any) => ({
  type: types.INPUT_INFO_BUYER_SUCCESS,
  payload,
});
export const InputInfoBuyerAddLicenseSuccess = (payload: any) => ({
  type: types.INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS,
  payload,
});
export const InputInfoBuyerAddLicenseSuccess2 = (payload: any) => ({
  type: types.INPUT_INFO_BUYER_ADD_LICENSE_SUCCESS2,
  payload,
});
export const InfoBuyerSuccess = (payload: any) => ({
  type: types.INFO_BUYER_SUCCESS,
  payload,
});
export const CheckIsReadInfo = (payload: any) => ({
  type: types.CHECK_IS_READ,
  payload,
});
