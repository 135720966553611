/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, Divider, Button, List, ListItem, ListItemText, Alert, Box, Checkbox } from '@mui/material';
import React, { ReactNode, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { start } from '../../../lib/saga-promise';
import { ColumnBuyLicense, columns, SearchLicenseRenew, SearchLicenseRenewDefault } from './types';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import TGGrid from '../../../component/Elements/TGGrid';
import SearchResult from '../../../component/Layout/SearchResult';
import { getLicensesByUser } from '../../../state/actions/license';
import OverHideText from '../../../component/Elements/OverHideText';
import { LICENSE_TRAIL, SIZE_PAGE } from '../../../constants/app';
import { PRICE_LICENSE } from '../../../constants/prices';
import routes from '../../../constants/routes';
import StepPurchase from '../stepPurchase';
import DialogConfirmCancel from '../confirmCancel';

function BuyLicense() {
  const [rows, setRows] = useState<any | null>();
  const getLicense = async (data: UnpackNestedValue<SearchLicenseRenew>) => {
    const dataInput = {
      ...data,
      userId: param.userId,
      isBuyLicenseTrial: true,
    };
    const licenses: any = await start(getLicensesByUser, dataInput, dispatch);
    if (licenses.count === 0) {
      window.location.href = routes.pageNotFound;
    }
    setRows(licenses);

    const allRowIds = licenses.list.map((item: any) => item.licenseId);
    setSelectedRows(allRowIds);
    setIsNotSelectedRows([]);
  };

  const param = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, getValues, setValue } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });
  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(getLicense)();
  };

  useEffect(() => {
    handleSubmit(getLicense)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isNotSelectedRows, setIsNotSelectedRows] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [totalAmount, setTotalAmount] = useState('0');
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
      setIsNotSelectedRows(isNotSelectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
      setIsNotSelectedRows([...isNotSelectedRows, rowId]);
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedRows]);

  // set default checked all
  useEffect(() => {
    const calculatedMoney = selectedRows.length * PRICE_LICENSE;
    setTotalAmount(calculatedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  }, [selectedRows]);

  const tabs = [
    {
      title: t('purchaseStepBuyLicense'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codeError, setCodeError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      navigate(routes.purchase);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  // mouse event in dialog
  const [showErrors, setShowErrors] = useState(true);
  const handleFormSubmit = () => {
    if (param.userId) {
      const extensionLicenses = {
        userId: param.userId,
        listLicense: selectedRows,
        listLicenseNotBuy: isNotSelectedRows,
        totalAmount,
        startDate: rows.list[0].startDate,
        endDate: rows.list[0].purchaseType === LICENSE_TRAIL ? rows.list[0].endDateTrail : rows.list[0].endDate,
      };
      localStorage.setItem('extensionLicenses', JSON.stringify(extensionLicenses));
      localStorage.setItem('userId', param.userId);
      window.location.href = `/purchase/trialConfirm/${param.userId}`;
      // const urlPayment: any = await start(editLicensesStatus, extensionLicenses, dispatch);
      // setCodeError('');
      // if (typeof param.userId === 'string') {
      //   window.location.href = urlPayment;
      // }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseDialogPaymentMethod = () => {
    setOpenPaymentMethod(false);
  };
  // const handleConfirmationPaymentMethod = (confirm: boolean) => {
  //   setShowErrors(true);
  //   if (confirm) {
  //     handleSubmit(onSubmit)();
  //   } else {
  //     handleSubmit(onSubmitBankTransfer)();
  //   }
  // };
  // const onSubmit: SubmitHandler<RegisterInformation> = async (data) => {
  //   try {
  //     const startDateObj = new Date(data.startDate);
  //     startDateObj.setHours(23);
  //     startDateObj.setMinutes(59);

  //     const dataInput = {
  //       ...data,
  //       mailRegister,
  //       licensePurchaser,
  //       totalAmount,
  //       startDate: startDateObj,
  //     };
  //     localStorage.setItem('dataInput', JSON.stringify(dataInput));
  //     if (param.id) {
  //       localStorage.setItem('oneTimeKeyId', param.id);
  //       window.location.href = '/purchase/ConfirmInfo';
  //     }
  //   } catch (error: any) {
  //     let err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[{}]/g, '').trim();
  //     if (err.split('=').length > 1) {
  //       err = err.split('=')[1].trim();
  //     }
  //     if (err === 'purchaseEmail.emailRegistered') {
  //       setCodeError(t('purchaseEmail.emailRegistered'));
  //     } else {
  //       setCodeError(t('purchaseEmail.systemError'));
  //     }
  //     window.scrollTo(0, 0);
  //   }
  // };

  // event change language, turn off errors message
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  // show errors when click button submit
  // const handleFormSubmit = () => {
  //   setShowErrors(true);
  //   handleSubmit(onSubmit)();
  // };

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <StepPurchase tabs={tabs} idx={0} />
        <TGGrid container columns={12} mt={8}>
          <TGGrid item sm={12} mb={1}>
            {showErrors && codeError && (
              <Alert severity="error" icon={false}>
                {codeError}
              </Alert>
            )}
          </TGGrid>
          <TGGrid item xs={12}>
            <Typography variant="h3">{t('purchase.title.buyLicense')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={1}>
            <Typography variant="body1">{t('purchase.notes.description')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={3} mb={1}>
            <Typography variant="h4">{t('purchase.title.FreeTrialLicenseInformation')}</Typography>
          </TGGrid>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid>
          <SearchResult<ColumnBuyLicense>
            totalCount={rows?.count ?? 0}
            page={getValues('pageIndex')}
            columns={columns}
            rows={rows?.list ?? []}
            handlePageChange={pageChange}
            tableCell={(row: any, rowKey: number, column: ColumnBuyLicense): ReactNode => {
              const value = row[column.id];
              switch (column.id) {
                case 'licenseId':
                  return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
                case 'UserNameWithLoginId':
                  if (row.loginId) {
                    return `${row.userName}`;
                  }
                  return '';
                case 'availableDeviceNumber':
                  return `${row.availableDeviceNumber ?? 0}`;
                case 'isRenew':
                  return (
                    <Checkbox
                      checked={selectedRows.includes(row.licenseId)}
                      onChange={(event) => handleRowCheckboxChange(event, row.licenseId)}
                      color="primary"
                    />
                  );
                default:
                  return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
              }
            }}
          />
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
          <TGGrid item xs={12}>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsDescription1')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsDescription2')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsDescription3')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsDescription4')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDescriptionBuyLicense')} />
              </ListItem>
            </List>
          </TGGrid>
        </TGGrid>
        <Box display="flex" alignItems="center" justifyContent="start" my={1}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchasePaymentAmount')}
          </Typography>
          <Typography variant="body1" className="fs-09-rem" ml={6}>
            {totalAmount}
            {t('yenUnit')}
          </Typography>
        </Box>
        <TGGrid container mt={10} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} sm={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleOpenDialog}
              >
                {t('purchaseEmail.button.cancel')}
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                onClick={handleFormSubmit}
                disabled={!isChecked}
                // onClick={handleFormSubmit}
              >
                {t('purchase.btn.buyLicense')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
        <DialogConfirmCancel open={open} onClose={handleCloseDialog} onConfirm={handleConfirmation} />
      </PurchaseForm>
    </div>
  );
}

export default BuyLicense;
