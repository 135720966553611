export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const MANAGE_LICENSE = process.env.REACT_APP_MANAGE_LICENSE;
export const THUMGY_DOWNLOAD_LINK = 'https://www.sankei-bc.co.jp/thumgy-download';

export const USER_ROLE = [
  {
    value: 1,
    label: 'common.label.role1',
  },
  {
    value: 2,
    label: 'common.label.role2',
  },
  {
    value: 3,
    label: 'common.label.role3',
  },
  {
    value: 4,
    label: 'common.label.role3.personal',
  },
];
export const SIZE_PAGE = 20;
export const DEFAULT_PAGE = 1;

// Status of trial license
export const LICENSE_TRAIL = 'Trial';
export const LICENSE_WAIT_FOR_PAY = 'Waiting';
export const LICENSE_PAID = 'Paid';
export const LICENSE_EXPIRED = 'Expired';
