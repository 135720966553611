import { call, put, takeLatest } from 'redux-saga/effects';
import { changeLoading } from '../actions/app';
import { sagaPromise } from '../../lib/saga-promise';
import UserServices from '../../services/user';
import {
  types,
  creatUserSuccess,
  editUserSuccess,
  searchUserSuccess,
  userDetailSuccess,
  searchMUserSuccess,
  canLicenseBindSuccess,
  updateUserLicenseSuccess,
  passwordResetSuccess,
  passwordChangeSuccess,
  passwordExpiredChangeSuccess,
} from '../actions/user';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Iaction {
  type: string;
  payload: any;
}

function* createUser(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.createUser, action.payload);
    // yield put(creatUserSuccess(action.payload));
    yield put(creatUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}
function* searchMUser(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.searchMUser, action.payload);
    yield put(searchMUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* searchUser(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.searchUser, action.payload);
    yield put(searchUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* userDetail(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(UserServices.userDetail, action.payload);
    yield put(userDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* canLicenseBindUsers(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(UserServices.canLicenseBindUsers, action.payload);
    yield put(canLicenseBindSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editUser(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.editUser, action.payload);
    yield put(editUserSuccess(action.payload));
    // yield put(editUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* passwordReset(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.passwordReset, action.payload);
    yield put(passwordResetSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* passwordChange(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.passwordChange, action.payload);
    yield put(passwordChangeSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* updateUserLicense(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.updateUserLicense, action.payload);
    yield put(updateUserLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}
function* passwordExpiredChange(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserServices.passwordExpiredChange, action.payload);
    yield put(passwordExpiredChangeSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* userSaga() {
  yield takeLatest(types.CREATE_USER, sagaPromise(createUser));
  yield takeLatest(types.EDIT_USER, sagaPromise(editUser));
  yield takeLatest(types.SEARCH_MUSER, sagaPromise(searchMUser));
  yield takeLatest(types.SEARCH_USER, sagaPromise(searchUser));
  yield takeLatest(types.USER_DETAIL, sagaPromise(userDetail));
  yield takeLatest(types.CAN_LICENSE_BIND, sagaPromise(canLicenseBindUsers));
  yield takeLatest(types.PASSWORD_RESET, sagaPromise(passwordReset));
  yield takeLatest(types.PASSWORD_CHANGE, sagaPromise(passwordChange));
  yield takeLatest(types.UPDATE_USER_LICENSE, sagaPromise(updateUserLicense));
  yield takeLatest(types.PASSWORD_EXPIRED_CHANGE, sagaPromise(passwordExpiredChange));
}
