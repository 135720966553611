/* eslint-disable max-len */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import RegisterForm from '../../component/Layout/RegisterForm';
import TGTextField from '../../component/Elements/TGTextField';
import TGGrid from '../../component/Elements/TGGrid';
import { RegisterInput, schemaEdit, schemaNew } from './types';
import { createVersion, editVersion } from '../../state/actions/version';

export default function VersionCreate(_versionDetail: any = null) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isEditMode: boolean = Object.keys(_versionDetail).length > 0;
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const schema = isEditMode ? schemaEdit : schemaNew;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [method, setMethod] = useState<UseFormReturn<RegisterInput, object>>();
  const handleSubmit = async (
    data: UnpackNestedValue<RegisterInput>,
    _method: UseFormReturn<RegisterInput, object>
  ) => {
    if (data.versionReleaseDate) {
      data.versionReleaseDate = moment(data.versionReleaseDate).set({
        hour: moment().hours(),
        minute: moment().minutes(),
        second: moment().seconds(),
      }).format();
      // data.versionReleaseDate = moment(data.versionReleaseDate).set("hour",15).set("minute",12).set("second",22).format();
    }
    if (isEditMode) {
      const editData = {
        ...data, versionInfoId: _versionDetail.versionId
      };
      await start(editVersion, editData, dispatch);
    } else {
      await start(createVersion, data, dispatch);
      setMethod(_method);
    }
  };
  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={isEditMode ? t('versionCreate.label.topUpdate') : t('versionCreate.label.topReg')}
      isEditMode={isEditMode}
      handleCancel={() => history(routes.versionHistory)}
      options={{
        defaultValues: {
          versionNumber: isEditMode ? _versionDetail.versionNumber : '',
          versionReleaseDate: isEditMode ? moment(_versionDetail.versionReleaseDate).local().format('YYYY-MM-DD') : moment().local().format('YYYY-MM-DD'),
          versionReleaseNote: isEditMode ? _versionDetail.versionReleaseNote : '',
          versionReleaseNoteEngVer: isEditMode ? _versionDetail.versionReleaseNoteEngVer : '',
          titleEngVer: isEditMode ? _versionDetail.titleEngVer : '',
          titleJaVer: isEditMode ? _versionDetail.titleJaVer : '',
          versionRegisterPerson: `${user.username} < ${user.loginId} >`,
          versionRegisterDate: moment().format('MM/DD/YYYY HH:mm:ss')// t('versionCreate.label.registeredReleaseDate'),
        },
      }}
    >
      {({ register, formState: { errors } }) => (
        <TGGrid container spacing={2}>
          <TGGrid item sm={4} mb={2.7}>
            <TGTextField
              label={t('versionCreate.label.licenseRegist')}
              type="text"
              registration={register('versionNumber')}
              isError={'versionNumber' in errors}
              errorMessage={errors.versionNumber?.message}
            />
          </TGGrid>
          <TGGrid item sm={4} mb={2.7}>
            <TGTextField
              label={t('versionCreate.label.releaseDate')}
              type="date"
              registration={register('versionReleaseDate')}
              isError={'versionReleaseDate' in errors}
              errorMessage={errors.versionReleaseDate?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={8}>
            <TGTextField
              label={t('versionCreate.label.titleJaVer')}
              type="text"
              registration={register('titleJaVer')}
              isError={'titleJaVer' in errors}
              errorMessage={errors.titleJaVer?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={12}>
            <TGGrid item sm={12}>
              <TGTextField
                label={t('versionCreate.label.releaseNoteJaVer')}
                type="text"
                registration={register('versionReleaseNote')}
                isError={'versionReleaseNote' in errors}
                errorMessage={errors.versionReleaseNote?.message}
                multiline
                rows={6}
                maxRows={6}
              />
            </TGGrid>
          </TGGrid>
          <TGGrid item xs={12} sm={8}>
            <TGTextField
              label={t('versionCreate.label.titleEnVer')}
              type="text"
              registration={register('titleEngVer')}
              isError={'titleEngVer' in errors}
              errorMessage={errors.titleEngVer?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={12}>
            <TGGrid item sm={12}>
              <TGTextField
                label={t('versionCreate.label.releaseNoteEngVer')}
                type="text"
                registration={register('versionReleaseNoteEngVer')}
                isError={'versionReleaseNoteEngVer' in errors}
                errorMessage={errors.versionReleaseNoteEngVer?.message}
                multiline
                rows={6}
                maxRows={6}
              />
            </TGGrid>
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField
              label={t('versionCreate.label.registeredPerson')}
              isDisabled
              registration={register('versionRegisterPerson')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={8}>
            <TGTextField
              label={t('versionCreate.label.registeredDate')}
              isDisabled
              registration={register('versionRegisterDate')}
            />
          </TGGrid>
        </TGGrid>
      )}
    </RegisterForm>
  );
}
