import { CsvFormat, TableColumn } from '../../../types';

export type Column = TableColumn & {
  id: string;
};

export const columns: Column[] = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
  },
  {
    id: 'password',
    label: 'common.label.password',
  },
  {
    id: 'countryId',
    label: 'common.label.countryId',
  },
  {
    id: 'userName',
    label: 'common.label.username',
  },
  {
    id: 'furigana',
    label: 'common.label.furigana',
  },
  {
    id: 'email',
    label: 'common.label.email',
  },
  {
    id: 'nickName',
    label: 'common.label.nickname',
  },
  {
    id: 'postcode',
    label: 'common.label.postcode',
  },
  {
    id: 'address',
    label: 'common.label.address',
  },
  {
    id: 'phoneNumber',
    label: 'common.label.phoneNumber',
  },
  {
    id: 'profession',
    label: 'purchaseProfession',
  },
  {
    id: 'workplace',
    label: 'purchaseWorkplace',
  },
  {
    id: 'roleId',
    label: 'common.label.role',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'comment',
    label: 'csvFormat.label.description',
  },
  {
    id: 'isUpdate',
    label: 'common.label.import.status',
    align: 'center',
  },
];
export type UserFormInput = {
  groupCode: string;
  loginId: string;
  userName: string;
  email: string;
  countryId: string;
  nickName: string;
  isUpdate: boolean;
};

export const rowsCsvFormat: CsvFormat[] = [
  {
    no: 'userImport.label.csvANo',
    name: 'userImport.label.csvAName',
    required: false,
    description: 'userImport.label.csvADescription',
  },
  {
    no: 'userImport.label.csvBNo',
    name: 'userImport.label.csvBName',
    required: true,
    description: 'userImport.label.csvBDescription',
  },
  {
    no: 'userImport.label.csvCNo',
    name: 'userImport.label.csvCName',
    required: false,
    description: 'userImport.label.csvCDescription',
  },
  {
    no: 'userImport.label.csvDNo',
    name: 'userImport.label.csvDName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvENo',
    name: 'userImport.label.csvEName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvFNo',
    name: 'userImport.label.csvFName',
    required: true,
    description: 'userImport.label.csvFDescription',
  },
  {
    no: 'userImport.label.csvGNo',
    name: 'userImport.label.csvGName',
    required: true,
    description: 'userImport.label.csvGDescription',
  },
  {
    no: 'userImport.label.csvHNo',
    name: 'userImport.label.csvHName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvINo',
    name: 'userImport.label.csvIName',
    required: true,
    description: 'userImport.label.csvIDescription',
  },
  {
    no: 'userImport.label.csvJNo',
    name: 'userImport.label.csvJName',
    required: true,
    description: 'userImport.label.csvJDescription',
  },
  {
    no: 'userImport.label.csvKNo',
    name: 'userImport.label.csvKName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvLNo',
    name: 'userImport.label.csvLName',
    required: true,
    description: 'userImport.label.csvLDescription',
  },
  {
    no: 'userImport.label.csvMNo',
    name: 'userImport.label.csvMName',
    required: false,
    description: '',
  },
  {
    no: 'userImport.label.csvNNo',
    name: 'userImport.label.csvNName',
    required: false,
    description: '',
  },
  {
    no: 'userImport.label.csvONo',
    name: 'userImport.label.csvOName',
    required: true,
    description: 'userImport.label.csvODescription',
  },
  {
    no: 'userImport.label.csvPNo',
    name: 'userImport.label.csvPName',
    required: false,
    description: '',
  },
];



export const rowsCsvFormatLocaleEnglish: CsvFormat[] = [
  {
    no: 'userImport.label.csvANo',
    name: 'userImport.label.csvAName',
    required: false,
    description: 'userImport.label.csvADescription',
  },
  {
    no: 'userImport.label.csvBNo',
    name: 'userImport.label.csvBName',
    required: true,
    description: 'userImport.label.csvBDescription',
  },
  {
    no: 'userImport.label.csvCNo',
    name: 'userImport.label.csvCName',
    required: false,
    description: 'userImport.label.csvCDescription',
  },
  {
    no: 'userImport.label.csvDNo',
    name: 'userImport.label.csvDName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvENo',
    name: 'userImport.label.csvFName',
    required: true,
    description: 'userImport.label.csvFDescription',
  },
  {
    no: 'userImport.label.csvFNo',
    name: 'userImport.label.csvGName',
    required: true,
    description: 'userImport.label.csvGDescription',
  },
  {
    no: 'userImport.label.csvGNo',
    name: 'userImport.label.csvHName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvHNo',
    name: 'userImport.label.csvIName',
    required: true,
    description: 'userImport.label.csvIDescription',
  },
  {
    no: 'userImport.label.csvINo',
    name: 'userImport.label.csvJName',
    required: true,
    description: 'userImport.label.csvJDescription',
  },
  {
    no: 'userImport.label.csvJNo',
    name: 'userImport.label.csvKName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvKNo',
    name: 'userImport.label.csvLName',
    required: true,
    description: 'userImport.label.csvLDescription',
  },
  {
    no: 'userImport.label.csvLNo',
    name: 'userImport.label.csvMName',
    required: false,
    description: '',
  },
  {
    no: 'userImport.label.csvMNo',
    name: 'userImport.label.csvNName',
    required: false,
    description: '',
  },
  {
    no: 'userImport.label.csvNNo',
    name: 'userImport.label.csvOName',
    required: true,
    description: 'userImport.label.csvODescription',
  },
  {
    no: 'userImport.label.csvONo',
    name: 'userImport.label.csvPName',
    required: false,
    description: '',
  },
];
