// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class VersionServices {
  static createVersion(params: any) {
    const url = 'version/create';
    return axiosClient.post(url, params);
  }

  static editVersion(params: any) {
    const url = 'version/edit';
    return axiosClient.put(url, params);
  }

  static searchVersions(params: any) {
    const url = 'version/search';
    return axiosClient.get(url, { params });
  }

  static cancelVersion(params: any) {
    const url = 'version/cancel';
    return axiosClient.put(url, params);
  }

  static resetCancelVersion(params: any) {
    const url = 'version/reset_cancel';
    return axiosClient.put(url, params);
  }

  static versionDetail(params: any) {
    const url = 'version/detail';
    return axiosClient.get(url, { params });
  }
}
export default VersionServices;
