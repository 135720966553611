import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TGGrid from '../component/Elements/TGGrid';
import PurchaseForm from '../component/Layout/PurchaseForm';
import routes from '../constants/routes';

function ExpiredPage() {
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = routes.purchase;
  };

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <TGGrid container columns={12} mt={8} ml={4}>
          <TGGrid item xs={12}>
            <Typography variant="h3">{t('titleExpiredPage')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2} ml={4}>
            <Typography variant="body1">{t('titleExpiredPageContent')}</Typography>
          </TGGrid>
          <div style={{ margin: 'auto' }}>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Box sx={{ mt: 2 }}>
                <Button
                  disableElevation
                    // fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  {t('buttonExpiredPage')}
                </Button>
              </Box>
            </TGGrid>
          </div>
          <TGGrid item xs={12} mt={2}>
            <Typography variant="body1">{t('titleExpiredPageNote')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2} ml={4}>
            <Typography variant="body1">{t('contentExpiredPageNote1')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2} ml={4}>
            <Typography variant="body1">{t('contentExpiredPageNote2')}</Typography>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
    </div>
  );
}

export default ExpiredPage;
