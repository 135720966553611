/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, TextFieldProps } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';

type TGTextFieldProps = TextFieldProps & {
  label: React.ReactNode;
  name?: string;
  type?: 'text' | 'email' | 'password' | 'date' | 'number';
  isDisabled?: boolean;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  registration?: Partial<UseFormRegisterReturn>;
  isError?: boolean;
  errorMessage?: React.ReactNode;
  hidePasswordToggle?: boolean;
};

export default function TGTextField({
  label,
  name,
  type,
  isDisabled,
  value,
  onChange,
  registration,
  isError,
  errorMessage,
  hidePasswordToggle = false,
  ...props
}: TGTextFieldProps) {
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  const { t } = useTranslation();
  // const disableUnderline = !isEditable || isDisabled ? { disableUnderline: true } : {};
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      fullWidth
      variant={!isEditable || isDisabled ? 'standard' : 'outlined'}
      size="small"
      label={label}
      name={name}
      type={showPassword ? 'text' : type}
      InputProps={{
        readOnly: !isEditable || isDisabled,
        endAdornment: type === 'password' && !isDisabled && !hidePasswordToggle ? (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{
        shrink: type === 'date' ? true : undefined,
      }}
      value={value}
      onChange={onChange}
      {...registration}
      error={isError}
      helperText={t(errorMessage as string)}
      {...props}
    />
  );
}
