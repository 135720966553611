import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CancelConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (confirm: boolean) => void;
}

function DialogConfirmCancel({ open, onClose, onConfirm }: CancelConfirmationDialogProps) {
  const { t } = useTranslation();
  const handleCancel = () => {
    onClose();
    onConfirm(false);
  };
  const handleConfirm = () => {
    onClose();
    onConfirm(true);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{t('dialog.message.cancel')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="purchase-button-gray" onClick={handleCancel}>
          {t('btnNo')}
        </Button>
        <Button variant="contained" onClick={handleConfirm} autoFocus>
          {t('btnYes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirmCancel;
