import { Typography, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { start } from '../../lib/saga-promise';
import { searchInformationBuyer } from '../../state/actions/purchase';
import TGGrid from '../../component/Elements/TGGrid';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import StepPurchase from './stepPurchase';
import InformationCompany from './register/informationCompanyForm';
import UserCompany from './register/informationUserForm';

function CreateInformationBuyer() {
  const param = useParams();
  const dispatch = useDispatch();
  const buyerDetail = useSelector((state: RootStateOrAny) => state.purchase.buyer);

  // show layout when fetch api success
  const [flagShowLayout, setFlagShowLayout] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (param.id) {
        let review = false;
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('review') === 'true') {
          review = true;
        }
        await start(searchInformationBuyer, { oneTimeKeyId: param.id, review }, dispatch);
        setFlagShowLayout(true);
      }
    };
    fetchData();
  }, [dispatch, param.id]);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(buyerDetail.languages);
    sessionStorage.setItem('locale', buyerDetail.languages);
  }, [buyerDetail, i18n]);

  const tabs = [
    {
      title: t('purchaseStepInputMail'),
    },
    {
      title: t('purchaseStepSendMail'),
    },
    {
      title: t('purchaseStepInputInformationBuy'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];
  const listBuyerLicense = [
    {
      name: t('purchaseForCorporateUse'),
      value: '0',
    },
    {
      name: t('purchaseForPersonalUse'),
      value: '1',
    },
  ];
  const [licensePurchaser, setLicensePurchaser] = useState('0');
  const changeObjectBuyLicense = (event: SelectChangeEvent) => {
    setLicensePurchaser(event.target.value as string);
  };

  const [formDataCompany, setFormDataCompany] = useState<any>({
    countryId: '',
    companyName: '',
    companyNameFurigana: '',
    applicationDepartment: '',
    businessOwnerName: '',
    businessOwnerNameFurigana: '',
    zipCode: '',
    address: '',
    telephoneNumber: '',
    amountOfLicense: 0,
    startDate: '',
    paymentAmount: '',
  });

  const [formDataUser, setFormDataUser] = useState<any>({
    countryId: '',
    businessOwnerName: '',
    businessOwnerNameFurigana: '',
    zipCode: '',
    address: '',
    telephoneNumber: '',
    startDate: '',
    profession: '',
    workplace: '',
  });

  useEffect(() => {
    if (localStorage.getItem('infoForm') != null) {
      const storedObjectString = localStorage.getItem('infoForm');
      if (storedObjectString !== null) {
        if (storedObjectString === 'UserForm') {
          setLicensePurchaser('1');
        }
      }
    }
    if (localStorage.getItem('dataInput') != null) {
      // const storedObjectString = localStorage.getItem('dataInput');
      const storedDataInput = localStorage.getItem('dataInput');
      if (storedDataInput !== null) {
        const storedData = JSON.parse(storedDataInput);
        // setDataInputLocalStore(storedData);
        const selectedDateTime = new Date(storedData.startDate);
        selectedDateTime.setFullYear(selectedDateTime.getFullYear());
        const formattedEndDate = `${selectedDateTime.getFullYear()}-${(selectedDateTime.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${selectedDateTime.getDate().toString().padStart(2, '0')}`;
        storedData.startDate = formattedEndDate;
        if (localStorage.getItem('infoForm') != null) {
          const storedObjectString = localStorage.getItem('infoForm');
          if (storedObjectString !== null) {
            if (storedObjectString === 'UserForm') {
              setFormDataUser(storedData);
              localStorage.removeItem('infoForm');
            }
          }
        } else {
          setFormDataCompany(storedData);
        }
      }
    }
  }, []);

  if (flagShowLayout) {
    return (
      <div className="purchase__container">
        <PurchaseForm>
          <StepPurchase tabs={tabs} idx={2} />
          <Typography variant="h3" mt={3} mb={1}>
            {t('purchaseBuyerInformation.label.top')}
          </Typography>
          <Typography variant="h5" mb={2} color="inherit">
            {t('purchaseBuyerInformation.label.description')}
          </Typography>
          <TGGrid container direction="row" justifyContent="start" alignItems="center">
            <TGGrid item mr={2} sm={2} md={1} xs={3}>
              <Typography variant="h4">{t('purchaseApplicationCategory')}</Typography>
            </TGGrid>
            <TGGrid item md={2} xs={5}>
              <FormControl fullWidth>
                <Select id="license-purchaser" size="small" value={licensePurchaser} onChange={changeObjectBuyLicense}>
                  {listBuyerLicense.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TGGrid>
            <TGGrid item xs={12}>
              {licensePurchaser === '0' ? (
                <InformationCompany
                  licensePurchaser={licensePurchaser}
                  mailRegister={buyerDetail.emailRegister ? buyerDetail.emailRegister : ''}
                  formData={formDataCompany}
                  setFormData={setFormDataCompany}
                />
              ) : (
                <UserCompany
                  licensePurchaser={licensePurchaser}
                  mailRegister={buyerDetail.emailRegister ? buyerDetail.emailRegister : ''}
                  formData={formDataUser}
                  setFormData={setFormDataUser}
                />
              )}
            </TGGrid>
          </TGGrid>
        </PurchaseForm>
      </div>
    );
  }
  return <div className="waiting" />;
}

export default CreateInformationBuyer;
