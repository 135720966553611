/* eslint-disable react/no-unstable-nested-components */
import { Typography, Divider } from '@mui/material';
import { ReactNode, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useForm, UnpackNestedValue } from 'react-hook-form';
import moment from 'moment';
import { start } from '../../../lib/saga-promise';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from '../register/types';
import TGGrid from '../../../component/Elements/TGGrid';
import SearchResult from '../../../component/Layout/SearchResult';
import { MANAGE_LICENSE, SIZE_PAGE, THUMGY_DOWNLOAD_LINK } from '../../../constants/app';
import OverHideText from '../../../component/Elements/OverHideText';
import { getCountryById } from '../../../state/actions/country';
import { searchLicensesInPurchase } from '../../../state/actions/license';

type Props = {
  buyerDetail?: any;
};

function InformationCompany({ buyerDetail }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countryName, SetCountryName] = useState('');

  const { setValue, getValues, handleSubmit } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  // get list license
  const rows = useSelector((state: RootStateOrAny) => state.license);
  useEffect(() => {
    start(searchLicensesInPurchase, { GroupId: buyerDetail.groupId }, dispatch);
    start(getCountryById, { countryId: buyerDetail.countryId }, dispatch);
  }, [dispatch, buyerDetail]);

  // get list country
  const dataCountry = useSelector((state: RootStateOrAny) => state.country.country);
  useEffect(() => {
    if (dataCountry.countryNameJp) {
      SetCountryName(dataCountry.countryNameJp);
    }
  }, [dataCountry]);

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(searchLicensesInPurchase, { GroupId: buyerDetail.groupId, PageIndex: data.pageIndex }, dispatch);
  };

  // event change page in table license
  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  // get gmt offset
  const gmtOffset = moment().utcOffset() / 60;

  const manageLicense = MANAGE_LICENSE;
  const downloadThumgy = THUMGY_DOWNLOAD_LINK;

  return (
    <>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.startDate')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.startDate')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{moment(buyerDetail.startTimeLicense).add(gmtOffset, 'hours').format('YYYY/MM/DD')}</Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.corporateInfo')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1} spacing={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.idCorporate')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.groupCode}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>{ }</TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.nameCorporate')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.groupName}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>{ }</TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.applicationDepartment')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.department}</Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.corporateAdminInfo')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={2} mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.userId')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.loginId}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>{ }</TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.fullName')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.userName}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>{ }</TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.emailAddress')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography component="a" href={`mailto:${buyerDetail.mailRegister}`} variant="body1" className="fs-09-rem">{buyerDetail.mailRegister}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>{ }</TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.countryName')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{countryName}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>{ }</TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.nickname')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.nickName}</Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.nicknameDecription')}</Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2} mb={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.temporaryPassword')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{buyerDetail.password}</Typography>
        </TGGrid>
      </TGGrid>
      {/* {buyerDetail.bankCode !== '' && (
      <>
        <Typography variant="h4" mt={3} mb={1}>
          {t('TransferInformation')}
        </Typography>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={2} mt={1}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('BankCode')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.bankCode}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>{ }</TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('BankName')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.bankName}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>{ }</TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('BranchCode')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.branchCode}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>{ }</TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('BranchName')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.branchName}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>{ }</TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('AccountType')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.accountType}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>{ }</TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('AccountNumber')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.accountNumber}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>{ }</TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">{t('AccountHolder')}</Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">{buyerDetail.accountHolderName}</Typography>
          </TGGrid>
        </TGGrid>
      </>
      )} */}
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.licenseInfo')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.numberOfLicenses')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{rows?.total ?? 0}</Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.licenseInfo')}</Typography>
        </TGGrid>
        <TGGrid item md={7} xs={8} className="fs-09-rem font-NotoSansJP font-pdf">
          <SearchResult<Column>
            totalCount={rows.total}
            page={getValues('pageIndex')}
            columns={columns}
            rows={rows?.licenses}
            handlePageChange={pageChange}
            tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
              const value = row[column.id];
              switch (column.id) {
                case 'licenseId':
                  return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
                case 'licenseNumber':
                  return row.licenseCode;
                case 'numberOfAvailableTerminals':
                  return row.availableDeviceNumber;
                case 'dateOfExpiry':
                  return (row.purchaseType === 'Trial') ? moment(row.endDateTrail).format('YYYY/M/DD') : moment(row.endDate).format('YYYY/M/DD');
                default:
                  return <OverHideText>{value}</OverHideText>;
              }
            }}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.licenseManage')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" component="a" href={manageLicense} className="fs-09-rem font-NotoSansJP">{manageLicense}</Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1} mb={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{ }</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('purchaseBuyerInformation.success.label.licenseDecription')}</Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.required')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <Typography variant="h5" mt={2} mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.required.description1')}
      </Typography>
      <Typography variant="h5" mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.required.description2')}
      </Typography>
      <TGGrid container direction="row" justifyContent="start">
        <Typography variant="h5" mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
          {t('purchaseBuyerInformation.success.label.required.description3')}
        </Typography>
        <Typography variant="body1" component="a" href={downloadThumgy} className="fs-09-rem font-NotoSansJP">{downloadThumgy}</Typography>
      </TGGrid>
    </>
  );
}

InformationCompany.defaultProps = {
  buyerDetail: [],
};

export default InformationCompany;
