/* eslint-disable react/require-default-props */
import { FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useSelector, RootStateOrAny } from 'react-redux';

type Props = {
  defaultChecked?: boolean;
  registration: Partial<UseFormRegisterReturn>;
};

export default function IsCanLogin({ defaultChecked, registration }: Props) {
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  return (
    <FormControlLabel
      control={<Switch defaultChecked={defaultChecked} {...registration} disabled={!isEditable} />}
      label={t('common.label.IsCanLogin') as string}
    />
  );
}
