import moment from 'moment';
import { DEFAULT_PAGE } from '../../../constants/app';
import { TableColumn } from '../../../types';

export type Column = TableColumn & {
  id:
    | 'licenseDeviceId'
    | 'groupNameWithCode'
    | 'licenseCode'
    | 'availableDeviceNumber'
    | 'startDate'
    | 'endDate'
    | 'deviceName'
    | 'versionApp'
    | 'deviceRegistDate'
    | 'deviceCancelDate'
    | 'UserNameWithLoginId'
    | 'cancelAction'
    | 'macAddressPC'
    | 'timeZone'
    | 'osLanguage'
    | 'tgdLanguage'
    | 'cpu'
    | 'memory'
    | 'oSversion'
    | 'updateVersionDate'
    | 'hardDiskInfo'
    | 'ipAddress'
    | 'resetCancelAction';
};

export const columns: Column[] = [
  {
    id: 'licenseDeviceId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'startDate',
    label: 'common.label.startDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'UserNameWithLoginId',
    label: 'common.label.userNameWithLoginId',
  },
  {
    id: 'deviceName',
    label: 'licenseDevice.label.deviceName',
  },
  {
    id: 'versionApp',
    label: 'licenseDevice.label.versionApp',
    align: 'center',
  },
  {
    id: 'deviceRegistDate',
    label: 'licenseDevice.label.deviceRegistDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'updateVersionDate',
    label: 'versionSearch.label.updateVersionDate',
    align: 'center',
  },
  {
    id: 'cancelAction',
    label: 'licenseDevice.label.release',
    align: 'center',
  },
  {
    id: 'deviceCancelDate',
    label: 'licenseDevice.label.deviceCancelDate',
    format: (value: Date | null) => (value ? moment(value).format('YYYY/MM/DD') : ''),
    align: 'center',
  },
  {
    id: 'resetCancelAction',
    label: 'versionSearch.label.resetCancelAction',
    align: 'center',
  },
  {
    id: 'macAddressPC',
    label: 'versionSearch.label.macAddressPC',
    align: 'center',
  },
  {
    id: 'timeZone',
    label: 'versionSearch.label.timeZone',
    align: 'center',
  },
  {
    id: 'osLanguage',
    label: 'versionSearch.label.osLanguage',
    align: 'center',
  },
  {
    id: 'tgdLanguage',
    label: 'versionSearch.label.tgdLanguage',
    align: 'center',
  },
  {
    id: 'cpu',
    label: 'versionSearch.label.cpu',
    align: 'center',
  },
  {
    id: 'memory',
    label: 'versionSearch.label.memory',
    align: 'center',
  },
  {
    id: 'oSversion',
    label: 'versionSearch.label.oSversion',
    align: 'center',
  },
  {
    id: 'hardDiskInfo',
    label: 'versionSearch.label.hardDiskInfo',
    align: 'center',
  },
  {
    id: 'ipAddress',
    label: 'IP Address',
    align: 'center',
  }
];

export type ColumnUser = TableColumn & {
  id:
    | 'licenseDeviceId'
    | 'groupNameWithCode'
    | 'licenseCode'
    | 'availableDeviceNumber'
    | 'startDate'
    | 'endDate'
    | 'deviceName'
    | 'versionApp'
    | 'deviceRegistDate'
    | 'deviceCancelDate'
    | 'UserNameWithLoginId'
    | 'cancelAction'
    | 'resetCancelAction';
};

export const columnsUser: ColumnUser[] = [
  {
    id: 'licenseDeviceId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'startDate',
    label: 'common.label.startDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'UserNameWithLoginId',
    label: 'common.label.userNameWithLoginId',
  },
  {
    id: 'deviceName',
    label: 'licenseDevice.label.deviceName',
  },
  {
    id: 'versionApp',
    label: 'licenseDevice.label.versionApp',
    align: 'center',
  },
  {
    id: 'deviceRegistDate',
    label: 'licenseDevice.label.deviceRegistDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'cancelAction',
    label: 'licenseDevice.label.release',
    align: 'center',
  },
  {
    id: 'deviceCancelDate',
    label: 'licenseDevice.label.deviceCancelDate',
    format: (value: Date | null) => (value ? moment(value).format('YYYY/MM/DD') : ''),
    align: 'center',
  },
  {
    id: 'resetCancelAction',
    label: 'versionSearch.label.resetCancelAction',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  groupId: string | null;
  licenseCode: string;
  username: string;
  pageIndex: number;
  terminalIsUnlocked: string;
  loginId: string;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  groupId: null,
  licenseCode: '',
  username: '',
  pageIndex: DEFAULT_PAGE,
  terminalIsUnlocked: '',
  loginId: '',
};
