import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { t } from 'i18next';

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  value: any;
};

export default function IsPublicationCorporateNameRadio({ handleChange, value }: Props) {
  return (
    <FormControl size="small">
      <RadioGroup row defaultValue="true" name="publicationCompanyName" onChange={handleChange} value={value}>
        <FormControlLabel
          value="true"
          control={<Radio />}
          label={t('IsPublicationCorporateName.radio.yes') as string}
        />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label={t('IsPublicationCorporateName.radio.no') as string}
        />
      </RadioGroup>
    </FormControl>
  );
}
