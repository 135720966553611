import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Alert,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  ListItemText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import Logo from '../layout/main/header/logo';
import { passwordExpiredChange } from '../../state/actions/user';
import { logout } from '../../state/actions/auth';
import { groupPasswordPolicyDetail } from '../../state/actions/group_password_policy';
import { getRegExpSchemaPasswordPolicy } from '../../component/helpers/utility';

interface FormInput {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export default function PasswordExpiredChange() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state: RootStateOrAny) => state.auth.loggedIn);
  const history = useNavigate();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [codeError, setCodeError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const userDetail = useSelector((state: RootStateOrAny) => state.auth.user);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const policyDetail = useSelector((state: RootStateOrAny) => state.group_password_policy.group_password_policy);
  const [schema, setSchema] = useState(yup.object());

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!loggedIn) {
      history(routes.login);
    }
    if (userDetail) {
      const setValidate = async () => {
        const policyDetai: any = await start(groupPasswordPolicyDetail, { groupId: userDetail.groupId }, dispatch);
        const reg = getRegExpSchemaPasswordPolicy(
          policyDetai.isRequiredLowerAlphabetChars,
          policyDetai.isRequiredUpperAlphabetChars,
          policyDetai.isRequiredNumericChars,
          policyDetai.isRequiredMarkChars
        );
        let validateMsg = policyDetai.isRequiredLowerAlphabetChars ? t('PasswordLowerAlphabetChars') : '';
        validateMsg += policyDetai.isRequiredUpperAlphabetChars ? t('PasswordUpperAlphabetChars') : '';
        validateMsg += policyDetai.isRequiredNumericChars ? t('PasswordNumericChars') : '';
        validateMsg += policyDetai.isRequiredMarkChars ? t('PasswordMarkChars') : '';
        const schemaEdit = yup.object({
          newPassword: yup
            .string()
            .required(t('changePassword.msg.requiredNewPassword'))
            .min(
              policyDetai.minimumLength,
              t('Password107-1').replace('{0}', validateMsg).replace('{1}', policyDetai.minimumLength)
            )
            .max(127, t('changePassword.msg.requiredNewPassword'))
            .matches(reg, t('Password107-1').replace('{0}', validateMsg).replace('{1}', policyDetai.minimumLength)),
          newPasswordConfirm: yup
            .string()
            .oneOf([yup.ref('newPassword')], t('changePassword.msg.unmatchedNewPassword'))
            .required(t('changePassword.msg.requiredNewPasswordConfirm')),
        });
        setSchema(schemaEdit);
      };
      setValidate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userDetail.groupId, t]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });
  const handleLogout = () => {
    dispatch(logout());
    history(routes.login);
  };
  // const handleSubmit = async (data: UnpackNestedValue<RegisterInput>) => {
  //   await start(passwordChange, data, dispatch);
  // };
  const onSubmit: SubmitHandler<FormInput> = async (password) => {
    try {
      password.oldPassword = password.newPasswordConfirm;
      await start(passwordExpiredChange, password, dispatch);
      setCodeError('');
      setIsSuccess(true);
    } catch (error: any) {
      const { data } = error;
      const correctedData = data.replace(/=/g, ':');
      const validJsonString = correctedData.replace(/(\w+)/g, '"$1"');
      const jsonError = JSON.parse(validJsonString);

      if (jsonError?.errorCode) {
        setCodeError(t(jsonError.errorCode));
      } else if (jsonError?.message) {
        setCodeError(t(jsonError.message));
      } else {
        setCodeError(t('remind.msg.userId.notFound'));
      }
    }
  };

  return (
    <div className="login__container">
      <Card className="login__card">
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={5}>
            <Logo />
          </Box>
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography color={theme.palette.primary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
              {t('changePassword.label.passwordReset')}
            </Typography>
          </Stack>
          {codeError && (
            <Alert severity="error" icon={false}>
              {codeError}
            </Alert>
          )}
          <Grid item xs={12}>
            <Grid container direction="column" justifyContent="center" mt={2}>
              {!isSuccess && (
                <Stack>
                  <Typography variant="h5" color="inherit">
                    {t('changePassword.msg.expirationDate')}
                  </Typography>

                  <ListItemText
                    primary={(
                      <Typography variant="h5" color="inherit">
                        {t('changePassword.label.newPassword')}
                      </Typography>
                    )}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    id="newPassword"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { mt: 0 }
                    }}
                    sx={{ mt: 0 }}
                    {...register('newPassword')}
                    error={'newPassword' in errors}
                    helperText={errors.newPassword?.message}
                  />
                  <ListItemText
                    primary={(
                      <Typography variant="h5" color="inherit">
                        {t('changePassword.label.NewPasswordConfirm')}
                      </Typography>
                    )}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    type="password"
                    id="newPasswordConfirm"
                    autoComplete="current-password"
                    sx={{ mt: 0 }}
                    {...register('newPasswordConfirm')}
                    error={'newPasswordConfirm' in errors}
                    helperText={errors.newPasswordConfirm?.message}
                  />

                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('changePassword.btn.passwordRegister')}
                    </Button>
                  </Box>
                </Stack>
              )}
              {isSuccess && (
                <Stack>
                  <Stack sx={{ mb: 3 }}>
                    <Typography>{t('changePassword.msg.passwordRegister')}</Typography>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
          {/* <Divider /> */}
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom mt={2} sx={{ textAlign: 'left' }}>
              <Link onClick={handleLogout} to="#">
                {t('remind.link.returnLogin')}
              </Link>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
