/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { TextField, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

type Props = {
  Reads: Array<{ value: string; label: string }>;
  name?: string;
  hasBlank?: boolean;
  handleChange: (data: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  ReadStatus: string | undefined;
};

export default function RoleSelector({
  Reads,
  name,
  hasBlank = false,
  ReadStatus,
  handleChange,
}: Props) {
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  return (
    <TextField
      select
      fullWidth
      size="small"
      name={name}
      value={ReadStatus}
      onChange={handleChange}
      label={t('userInfoReadMessage.label.TerminalIsUnlocked')}
      variant={!isEditable ? 'standard' : 'outlined'}
      InputProps={{
        readOnly: !isEditable,
      }}
    >
      {hasBlank && (
        <MenuItem key="" value="">
          <br />
        </MenuItem>
      )}
      {Reads.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}
