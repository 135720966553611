/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/require-default-props */
import { FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useSelector, RootStateOrAny } from 'react-redux';

type Props = {
  defaultChecked?: boolean;
  registration: Partial<UseFormRegisterReturn>;
  onChange?: (checked: boolean) => void;
};

export default function IsDitributorFlag({ defaultChecked, registration, onChange }: Props) {
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  return (
    <FormControlLabel
      control={
        onChange ? (
          <Switch
            onClick={(e: any) => onChange(e.target.checked)}
            defaultChecked={defaultChecked}
            {...registration}
            disabled={!isEditable}
          />
        ) : (
          <Switch defaultChecked={defaultChecked} {...registration} disabled={!isEditable} />
        )
      }
      label={t('common.label.distributorFlag') as string}
    />
  );
}
