/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { columnCsvFormat, ColumnCsvFormat, CsvFormat as CsvFormatType } from '../../types';
import hasRole from '../../lib/hasRole';

type Props = {
  rows: CsvFormatType[];
};

export default function CsvFormat({ rows }: Props) {
  const { i18n } = useTranslation();
  const Locale = i18n.language;
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const hasAdmin = hasRole('systemAdmin', user);
  const tableCell = (row: CsvFormatType[], rowKey: number, column: ColumnCsvFormat) => {
    const value = row[(column as any).id];
    switch (column.id) {
      case 'required':
        if (rowKey === 0 || rowKey === 2 || rowKey === 5) {
          return (
            <Typography component="div" variant="body1">
              <Box sx={{ color: 'red' }}>△</Box>
            </Typography>
          );
        }
        if (value) {
          return (
            <Typography component="div" variant="body1">
              <Box sx={{ color: 'red' }}>※</Box>
            </Typography>
          );
        }
        return '';
      default:
        return t(value as unknown as string) as string;
    }
  };

  return (
    <Paper variant="outlined" sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader sx={{ whiteSpace: 'nowrap' }} size="small">
          <TableHead>
            <TableRow>
              {columnCsvFormat.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    width: column.width,
                    background: '#203462',
                    color: 'white',
                    pt: 1,
                    pb: 1,
                  }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {Locale === 'ja' ? (
            <TableBody>
              {rows?.map((row: any, rowKey: number) => (
                (hasAdmin || (!hasAdmin && rowKey < 9)) ? (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowKey}>
                    {columnCsvFormat.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {tableCell(row, rowKey, column)}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : null
              ))}
            </TableBody>
          )
            : (
              <TableBody>
                {rows?.map((row: any, rowKey: number) => (
                  (hasAdmin || (!hasAdmin && rowKey < 8)) ? (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rowKey}>
                      {columnCsvFormat.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {tableCell(row, rowKey, column)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : null
                ))}
              </TableBody>
            )}
        </Table>
      </TableContainer>
    </Paper>
  );
}
