export const types = {
  CREATE_VERSION: 'CREATE_VERSION',
  CREATE_VERSION_SUCCESS: 'CREATE_VERSION_SUCCESS',
  EDIT_VERSION: 'EDIT_VERSION',
  EDIT_VERSION_SUCCESS: 'EDIT_VERSION_SUCCESS',
  SEARCH_VERSIONS: 'SEARCH_VERSIONS',
  SEARCH_VERSIONS_SUCCESS: 'SEARCH_VERSIONS_SUCCESS',
  VERSION_DETAIL: 'VERSION_DETAIL',
  VERSION_DETAIL_SUCCESS: 'VERSION_DETAIL_SUCCESS',
  CANCEL_VERSION: 'CANCEL_VERSION',
  CANCEL_VERSION_SUCCESS: 'CANCEL_VERSION_SUCCESS',
  RESET_CANCEL_VERSION: 'RESET_CANCEL_VERSION',
  RESET_CANCEL_VERSION_SUCCESS: 'RESET_CANCEL_VERSION_SUCCESS',
};
export const createVersion = (payload: any) => ({
  type: types.CREATE_VERSION,
  payload
});

export const createVersionSuccess = (payload: any) => ({
  type: types.CREATE_VERSION_SUCCESS,
  payload
});

export const editVersion = (payload: any) => ({
  type: types.EDIT_VERSION,
  payload
});

export const editVersionSuccess = (payload: any) => ({
  type: types.EDIT_VERSION_SUCCESS,
  payload
});

export const searchVersions = (payload: any) => ({
  type: types.SEARCH_VERSIONS,
  payload
});

export const searchVersionSuccess = (payload: any) => ({
  type: types.SEARCH_VERSIONS_SUCCESS,
  payload
});

export const versionDetail = (payload: any) => ({
  type: types.VERSION_DETAIL,
  payload
});

export const versionDetailSuccess = (payload: any) => ({
  type: types.VERSION_DETAIL_SUCCESS,
  payload
});

export const cancelVersion = (payload: any) => ({
  type: types.CANCEL_VERSION,
  payload
});

export const cancelVersionSuccess = (payload: any) => ({
  type: types.CANCEL_VERSION_SUCCESS,
  payload
});

export const resetCancelVersion = (payload: any) => ({
  type: types.RESET_CANCEL_VERSION,
  payload
});

export const resetCancelVersionSuccess = (payload: any) => ({
  type: types.RESET_CANCEL_VERSION_SUCCESS,
  payload
});
