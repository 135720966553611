/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import { Paper, TextField, Autocomplete, Box } from '@mui/material';
import { t } from 'i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import ICountryDdl from '../../modal/ICountryDdl';

type Props = {
  countries: ICountryDdl[];
  handleChange: (data: ICountryDdl | null) => void;
  countryId: string;
  error?: boolean;
  message?: string | undefined;
  notLabel?: boolean;
};

export default function CountrySelector({ countries, handleChange, countryId, error, message, notLabel }: Props) {
  const changeCountry = (data: ICountryDdl | null) => {
    handleChange(data);
  };
  const setValue = (_countryId: string) => {
    const country = countries.find((item: ICountryDdl) => item.countryId === _countryId);
    return country ?? null;
  };

  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  return (
    <Autocomplete
      disablePortal
      size="small"
      readOnly={!isEditable}
      popupIcon={!isEditable ? '' : undefined}
      options={countries}
      isOptionEqualToValue={() => true}
      getOptionLabel={(option: any) => `${option.countryNameJp} <${option.countryId.toUpperCase()}>`}
      noOptionsText={t('error.country.notFound')}
      onChange={(_event, newValue: ICountryDdl | null) => {
        changeCountry(newValue);
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.alpha2.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.alpha2.toLowerCase()}.png 2x`}
            alt=""
          />
          {`${option.countryNameJp} <${option.countryId.toUpperCase()}>`}
        </Box>
      )}
      value={setValue(countryId)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={notLabel ? '' : t('common.label.countryTitle')}
          name="countryId"
          error={error}
          helperText={t(message as string)}
          variant={!isEditable ? 'standard' : 'outlined'}
        />
      )}
      PaperComponent={(props: any) => <Paper elevation={16} {...props} />}
    />
  );
}
