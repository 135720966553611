import * as yup from 'yup';
import moment from 'moment';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  ReadStatus: string;
};
const versionRegExp = /^\d+\.\d+\.\d+$/;
const versionReleaseNoteRegExp = /[^\s*].*[^\s*]/;
export const schemaEdit = yup.object({
  versionNumber: yup
    .string().typeError('versionCreate.msg.requiredVersionNumber')
    .required('versionCreate.msg.requiredVersionNumber')
    .matches(versionRegExp, 'versionCreate.err.requiredVersionNumberFormat')
    .max(64, 'versionCreate.err.requiredVersionNumberLength'),
  versionReleaseDate: yup
    .date()
    .typeError('versionCreate.msg.formatDate')
    .required('versionCreate.msg.requiredStartDate')
    .min('2000/01/01', 'versionCreate.msg.formatDate')
    .max('2999/12/31', 'versionCreate.msg.formatDate'),
  versionReleaseNote: yup
    .string()
    .typeError('versionCreate.msg.requiredVersionReleaseNote')
    .required('versionCreate.msg.requiredVersionReleaseNote')
    .matches(versionReleaseNoteRegExp, 'versionCreate.msg.requiredVersionReleaseNote')
    .max(1052, 'versionCreate.err.requiredVersionReleaseNoteLength'),
});

export const schemaNew = schemaEdit;

export type Column = TableColumn & {
  id:
    | 'userInfoId'
    | 'userID'
    | 'ReadStatus'
    | 'ReadDate'
    | 'titleJaVer'
    | 'UserName'
    | 'roleId'
};

export const columns: Column[] = [
  {
    id: 'userInfoId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'userID',
    label: 'userInfoReadMessage.label.userId',
    align: 'left',
  },
  {
    id: 'titleJaVer',
    label: 'versionCreate.label.titleJaVer',
    align: 'left',
  },
  {
    id: 'ReadDate',
    label: 'userInfoReadMessage.label.readcreatAt',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'roleId',
    label: 'common.label.role',
    align: 'left',
  },
  {
    id: 'UserName',
    label: 'userInfoReadMessage.label.UserName',
    align: 'left',
  },
  {
    id: 'ReadStatus',
    label: 'userInfoReadMessage.label.ReadStatus',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  ReadDateFrom: string;
  ReadDateTo: string;
  pageIndex: number;
  ReadStatus: string;
  GroupId: string;
  UserId: string,
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  ReadDateFrom: '',
  ReadDateTo: '',
  pageIndex: DEFAULT_PAGE,
  ReadStatus: '',
  GroupId: '',
  UserId: '',
};
