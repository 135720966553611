import { TableColumn } from '../../../types';
import { DEFAULT_PAGE } from '../../../constants/app';

export type RegisterInformation = {
  countryId: string;
  companyName: string;
  companyNameFurigana: string;
  applicationDepartment: string;
  businessOwnerName: string;
  businessOwnerNameFurigana: string;
  zipCode: string;
  address: string;
  telephoneNumber: string;
  profession: string;
  workplace: string;
  amountOfLicense: number;
  startDate: string;
  purchaseType: string;
  publicationCompanyName: boolean;
};

const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

export type Column = TableColumn & {
  id: 'licenseId' | 'licenseNumber' | 'numberOfAvailableTerminals' | 'dateOfExpiry';
};

export const columns: Column[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'licenseNumber',
    label: 'common.label.licenseNumber',
  },
  {
    id: 'numberOfAvailableTerminals',
    label: 'common.label.numberOfAvailableTerminals',
  },
  {
    id: 'dateOfExpiry',
    label: 'common.label.dateOfExpiry',
  },
];

export type SearchCriteriaInput = {
  countryId: string | null;
  companyName: string;
  companyNameFurigana: string;
  applicationDepartment: string;
  businessOwnerName: string;
  businessOwnerNameFurigana: string;
  emailAddress: string;
  zipCode: string;
  address: string;
  telephoneNumber: string;
  amountOfLicense: number;
  startDate: string;
  endDate: string;
  paymentAmount: string;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  countryId: '',
  companyName: '',
  companyNameFurigana: '',
  applicationDepartment: '',
  businessOwnerName: '',
  businessOwnerNameFurigana: '',
  emailAddress: '',
  zipCode: '',
  address: '',
  telephoneNumber: '',
  amountOfLicense: 0,
  startDate: '',
  endDate: '',
  paymentAmount: '',
  pageIndex: DEFAULT_PAGE,
};
