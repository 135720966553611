import { call, put, takeLatest } from 'redux-saga/effects';
import CountryServices from '../../services/country';
import { types, getCountrySuccess, getCountryByIdSuccess } from '../actions/country';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

interface Iaction {
  type: string,
  payload: any,
}

function* getCountries(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(CountryServices.getCountries, action.payload);
    yield put(getCountrySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getCountryById(action: Iaction) {
  try {
    const data: ResponseGenerator = yield call(CountryServices.getCountriesById, action.payload);
    yield put(getCountryByIdSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* CountrySaga() {
  yield takeLatest(types.GET_COUNTRIES, sagaPromise(getCountries));
  yield takeLatest(types.GET_COUNTRIES_BY_ID, sagaPromise(getCountryById));
}
