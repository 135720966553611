/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import StepPurchase from './stepPurchase';

export default function SendConfirmEmail() {
  const { t } = useTranslation();
  const tabs = [
    {
      title: t('purchaseStepInputMail'),
    },
    {
      title: t('purchaseStepSendMail'),
    },
    {
      title: t('purchaseStepInputInformationBuy'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    }
  ];
  return (
    <div className="login__container">
      <PurchaseForm>
        <StepPurchase tabs={tabs} idx={1} />
        <Typography mt={8} variant="h3">
          {t('purchaseSendEmail.label.title')}
        </Typography>
        <Typography mt={4}>
          {t('purchaseSendEmail.label.info1')}
        </Typography>
        <Typography mt={2}>
          {t('purchaseSendEmail.label.info2')}
        </Typography>
        <Typography mt={2}>
          {t('purchaseSendEmail.label.info3')}
        </Typography>
      </PurchaseForm>
    </div>
  );
}
