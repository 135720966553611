import { CsvFormat, TableColumn } from '../../../types';

export type Column = TableColumn & {
  id: 'no' | 'licenseCode' | 'loginId'| 'isDelete';
};

export const columns: Column[] = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
  },
  {
    id: 'isDelete',
    label: 'common.label.import.status',
  },
];
export type UserFormInput = {
  no: string;
  groupCode: string;
  loginId: string;
};

export const rowsCsvFormat: CsvFormat[] = [
  {
    no: 'licenseUserImport.label.csvANo',
    name: 'licenseUserImport.label.csvAName',
    required: true,
    description: 'licenseUserImport.label.csvADescription',
  },
  {
    no: 'licenseUserImport.label.csvBNo',
    name: 'licenseUserImport.label.csvBName',
    required: false,
    description: 'licenseUserImport.label.csvBDescription',
  }
];
