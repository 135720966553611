// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class UserInfoReadMessageServices {
  static getUMe(params: any) {
    const url = 'userinfo/getUserInfoReadMessage';
    return axiosClient.get(url, { params });
  }
}
export default UserInfoReadMessageServices;
