import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from './app';
import authReducer from './auth';
import userReducer from './user';
import groupReducer from './group';
import licenseReducer from './license';
import countryReducer from './country';
import deviceReducer from './device';
import versionReducer from './version';
import groupPasswordPolicyReducer from './group_password_policy';
import purchaseReducer from './purchase';
import settingReducer from './setting';

const configs = {
  key: 'license',
  blacklist: ['app', 'group', 'license', 'country', 'device', 'version', 'purchase', 'group_password_policy'],
  storage,
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  group: groupReducer,
  license: licenseReducer,
  country: countryReducer,
  device: deviceReducer,
  version: versionReducer,
  group_password_policy: groupPasswordPolicyReducer,
  purchase: purchaseReducer,
  setting: settingReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default persistReducer(configs, rootReducer);
