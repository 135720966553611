/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, InputAdornment, Typography } from '@mui/material';
import { start } from '../../../lib/saga-promise';
import routes from '../../../constants/routes';
import RegisterForm from '../../../component/Layout/RegisterForm';
import { RegisterInput, schemaEdit, schemaNew } from './types';
import TGTextField from '../../../component/Elements/TGTextField';
import TGGrid from '../../../component/Elements/TGGrid';
import roles from '../../../constants/roles';
import { editGroupPasswordPolicy } from '../../../state/actions/group_password_policy';
import { groupDetail } from '../../../state/actions/group';

export default function PasswordPolicyCreate(_policyDetail: any = null) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();
  const history = useNavigate();
  const isEditMode: boolean = Object.keys(_policyDetail).length > 0;
  const schema = isEditMode ? schemaEdit : schemaNew;
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const groupData = useSelector((state: RootStateOrAny) => state.group.group);

  useEffect(() => {
    if (param.id) {
      start(groupDetail, { groupId: param.id }, dispatch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data: UnpackNestedValue<RegisterInput>) => {
    if (isEditMode) {
      const editData = {
        ...data,
        groupId: param.id,
      };
      await start(editGroupPasswordPolicy, editData, dispatch);
    }
  };

  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={t('passwordPolicy.label.top')}
      isEditMode={isEditMode}
      handleCancel={() => history(routes.groupSearch)}
      readOnly={user.roleId === roles.user}
      options={{
        defaultValues: {
          groupNameWithCode: `${_policyDetail.groupName != null ? _policyDetail.groupName : groupData.groupName}<${
            _policyDetail.groupCode != null ? _policyDetail.groupCode : groupData.groupCode
          }>`,
          isRequiredLowerAlphabetChars: isEditMode ? _policyDetail.isRequiredLowerAlphabetChars : true,
          isRequiredUpperAlphabetChars: isEditMode ? _policyDetail.isRequiredUpperAlphabetChars : false,
          isRequiredNumericChars: isEditMode ? _policyDetail.isRequiredNumericChars : false,
          isRequiredMarkChars: isEditMode ? _policyDetail.isRequiredMarkChars : false,
          minimumLength: isEditMode ? _policyDetail.minimumLength : '',
          expireDays: isEditMode ? _policyDetail.expireDays : '',
          changeHistoryLimit: isEditMode ? _policyDetail.changeHistoryLimit : '',
          autoLockoutCount: isEditMode ? _policyDetail.autoLockoutCount : '',
          autoUnsetLockOutMinutes: isEditMode ? _policyDetail.autoUnsetLockOutMinutes : '',
        },
      }}
    >
      {({ register, formState: { errors } }) => (
        <TGGrid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
          <TGGrid item xs={12} sm={12}>
            <TGTextField
              label={t('common.label.groupnameGroupid')}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
              registration={register('groupNameWithCode')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <Typography variant="subtitle1">{t('passwordPolicy.label.specificationRequiredCharacter')}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('passwordPolicy.label.specificationRequiredCharacterDescription')}
            </Typography>
            <FormGroup sx={{ m: 1 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    defaultChecked={
                      !isEditMode || _policyDetail !== null ? _policyDetail.isRequiredLowerAlphabetChars : true
                    }
                    {...register('isRequiredLowerAlphabetChars')}
                  />
                )}
                label={t('passwordPolicy.chk.halfwidthAlphabeticalLower') as string}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    defaultChecked={_policyDetail !== null ? _policyDetail.isRequiredUpperAlphabetChars : false}
                    {...register('isRequiredUpperAlphabetChars')}
                  />
                )}
                label={t('passwordPolicy.chk.halfwidthAlphabeticalUpper') as string}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    defaultChecked={_policyDetail !== null ? _policyDetail.isRequiredNumericChars : false}
                    {...register('isRequiredNumericChars')}
                  />
                )}
                label={t('passwordPolicy.chk.halfwidthDigit') as string}
              />
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={_policyDetail.isRequiredMarkChars} {...register('isRequiredMarkChars')} />
                }
                label={t('passwordPolicy.chk.symbol') as string}
              />
            </FormGroup>
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <Typography variant="subtitle1">{t('passwordPolicy.label.minimumLength')}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('passwordPolicy.label.minimumLengthDescription')}
            </Typography>
            <TGTextField
              label={t('passwordPolicy.label.minimumLength')}
              isDisabled={isEditMode}
              registration={register('minimumLength')}
              isError={'minimumLength' in errors}
              errorMessage={errors.minimumLength?.message}
              sx={{ m: 1, width: 150 }}
              InputProps={{
                endAdornment: <InputAdornment position="start">{t('passwordPolicy.label.char')}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <Typography variant="subtitle1">{t('passwordPolicy.label.expirationDate')}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('passwordPolicy.label.expirationDateDescription')}
            </Typography>
            <TGTextField
              label={t('passwordPolicy.label.expirationDate')}
              isDisabled={isEditMode}
              registration={register('expireDays')}
              isError={'expireDays' in errors}
              errorMessage={errors.expireDays?.message}
              sx={{ m: 1, width: 150 }}
              InputProps={{
                endAdornment: <InputAdornment position="start">日</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 5,
              }}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <Typography variant="subtitle1">{t('passwordPolicy.label.changeHistoryGenerationLimit')}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('passwordPolicy.label.changeHistoryGenerationLimitDescription')}
            </Typography>
            <TGTextField
              label={t('passwordPolicy.label.historyGenerationNumber')}
              isDisabled={isEditMode}
              registration={register('changeHistoryLimit')}
              isError={'changeHistoryLimit' in errors}
              errorMessage={errors.changeHistoryLimit?.message}
              sx={{ m: 1, width: 150 }}
              InputProps={{
                endAdornment: <InputAdornment position="start">{t('passwordPolicy.label.generation')}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 2,
              }}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <Typography variant="subtitle1">{t('passwordPolicy.label.loginTryTimes')}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('passwordPolicy.label.loginTryTimesDescription')}
            </Typography>
            <TGTextField
              label={t('passwordPolicy.label.loginTryTimes')}
              isDisabled={isEditMode}
              registration={register('autoLockoutCount')}
              isError={'autoLockoutCount' in errors}
              errorMessage={errors.autoLockoutCount?.message}
              sx={{ m: 1, width: 150, display: 'flex' }}
              InputProps={{
                endAdornment: <InputAdornment position="start">回</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TGTextField
              label={t('passwordPolicy.label.autoCancellation')}
              isDisabled={isEditMode}
              registration={register('autoUnsetLockOutMinutes')}
              isError={'autoUnsetLockOutMinutes' in errors}
              errorMessage={errors.autoUnsetLockOutMinutes?.message}
              sx={{ m: 1, width: 150, display: 'flex' }}
              InputProps={{
                endAdornment: <InputAdornment position="start">{t('passwordPolicy.label.minutes')}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
          </TGGrid>
        </TGGrid>
      )}
    </RegisterForm>
  );
}
