/* eslint-disable max-len */
import * as yup from 'yup';
import { t } from 'i18next';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  groupCode: string;
  groupName: string;
  groupNameFurigana: string;
  department: string;
  postcode: string;
  address: string;
  phoneNumber: string;
  isInvalid: boolean;
  isAgent: boolean;
  countryId: string;
  IsCanLogin: boolean;
  groupNote: string;
  distributorFlag: boolean;
  distributorNumber: string;
  distributorName: string;
};

export const schemaEdit = yup.object({
  // groupCode: yup.string().required(('groupCreate.msg.requiredGroupid')).max(9, ('groupCreate.msg.requiredGroupid')),
  groupName: yup.string().required('groupCreate.msg.requiredGroupname').max(256, 'groupCreate.msg.requiredGroupname'),
  groupNameFurigana: yup
    .string()
    .required('groupCreate.msg.requiredgroupNameFurigana')
    .max(256, 'groupCreate.msg.requiredgroupNameFurigana'),
  department: yup
    .string()
    .required('groupCreate.msg.requiredDepartment')
    .max(256, 'groupCreate.msg.requiredDepartment'),
  postcode: yup.string().required('groupCreate.msg.requiredPostcode').max(50, 'groupCreate.msg.requiredPostcode'),
  address: yup.string().required('groupCreate.msg.requiredAddress').max(512, 'groupCreate.msg.requiredAddress'),
  phoneNumber: yup.string().required('groupCreate.msg.PhoneNumber').matches(/^[0-9]+$/, t('groupCreate.msg.requiredTelephoneNumberHalfWidth')).max(11, 'groupCreate.msg.PhoneNumber'),
  countryId: yup.string().required('groupCreate.msg.requiredCountryId'),
});

export const schemaNew = schemaEdit;

export type Column = TableColumn & {
  id:
    | 'groupId'
    | 'groupCode'
    | 'groupName'
    | 'countryId'
    | 'postcode'
    | 'address'
    | 'phoneNumber'
    | 'passwordPolicyLink'
    | 'publicationCompanyName'
    | 'isInvalid'
    | 'distributorFlag';
};

export const columns: Column[] = [
  {
    id: 'groupId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
  },
  {
    id: 'groupName',
    label: 'common.label.groupname',
  },
  {
    id: 'countryId',
    label: 'common.label.countryTitle',
  },
  {
    id: 'postcode',
    label: 'common.label.postcode',
  },
  {
    id: 'address',
    label: 'common.label.address',
  },
  {
    id: 'phoneNumber',
    label: 'common.label.phoneNumber',
  },
  {
    id: 'passwordPolicyLink',
    label: 'groupSearch.link.passwordPolicyLink',
  },
  {
    id: 'publicationCompanyName',
    label: 'PublicationCompanyName',
    align: 'center',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'distributorFlag',
    label: 'common.label.distributorFlag',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  groupCode: string;
  groupName: string;
  countryId: string;
  isInvalid: boolean;
  pageIndex: number;
  publicationCompanyName: string;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  groupCode: '',
  groupName: '',
  countryId: '',
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
  publicationCompanyName: '',
};
