/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { start } from '../../lib/saga-promise';
import { licenseDetail } from '../../state/actions/license';
import LicenseCreate from './create';

export default function LicenseEdit() {
  const dispatch = useDispatch();
  const param = useParams();
  const detail = useSelector((state: RootStateOrAny) => state.license.license);
  useEffect(() => {
    if (param.id) {
      start(licenseDetail, { licenseId: param.id }, dispatch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {detail.length !== 0 && detail.licenseId === param.id && <LicenseCreate {...detail} />}
    </>
  );
}
