function Icon10Day(props: any) {
  return (
    <svg
      width="1.1rem"
      height="1.1rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector 2"
        stroke="#0c3547"
        d="M14.0393 15.3223v5.2124c0 1.3615 1.1038 2.4653 2.4653 2.4653h1.8314c1.3616 0 2.4654 -1.1038 2.4654 -2.4653v-5.2124c0 -1.3616 -1.1038 -2.4654 -2.4654 -2.4654h-1.8314c-1.3615 0 -2.4653 1.1038 -2.4653 2.4654Z"
        strokeWidth="2"
      />
      <path
        id="Vector 2_2"
        stroke="#0c3547"
        d="m7.02954 12.8566 0 10.143"
        strokeWidth="2"
      />
      <path
        id="Vector 3"
        stroke="#0c3547"
        d="m3.64852 15.3923 0.84526 0c1.40046 0 2.53576 -1.1353 2.53576 -2.5357"
        strokeWidth="2"
      />
      <path
        id="Vector 1"
        stroke="#0c3547"
        d="M10.4109 22.9996H3.64885"
        strokeWidth="2"
      />
      <path
        id="Vector 2754"
        stroke="#0c3547"
        d="M2.72446 2.08118V6.5198h4.43863"
        strokeWidth="2"
      />
      <path
        id="Ellipse 653"
        stroke="#0c3547"
        d="m21.7483 7.0351 -0.4728 -0.83674C19.479 3.0926 16.121 1.00305 12.275 1.00305c-3.846 0 -7.20395 2.08955 -9.00054 5.19531l-0.1768 0.32117"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Icon10Day;
