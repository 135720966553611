import moment from 'moment';
import { CsvFormat, TableColumn } from '../../../types';

export type Column = TableColumn & {
  id: 'no' | 'licenseCode' | 'groupCode' | 'availableDeviceNumber' | 'startDate' | 'endDate' | 'isInvalid' | 'isUpdate';
};

export const columns: Column[] = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'startDate',
    label: 'common.label.startDate',
    align: 'center',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    align: 'center',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'isUpdate',
    label: 'common.label.import.status',
    align: 'center',
  },
];
export type UserFormInput = {
  groupCode: string;
  loginId: string;
  userName: string;
  email: string;
  countryId: string;
  nickName: string;
  isUpdate: boolean;
};

export const rowsCsvFormat: CsvFormat[] = [
  {
    no: 'licenseImport.label.csvANo',
    name: 'licenseImport.label.csvAName',
    required: false,
    description: 'licenseImport.label.csvADescription',
  },
  {
    no: 'licenseImport.label.csvBNo',
    name: 'licenseImport.label.csvBName',
    required: true,
    description: 'licenseImport.label.csvBDescription',
  },
  {
    no: 'licenseImport.label.csvCNo',
    name: 'licenseImport.label.csvCName',
    required: true,
    description: '',
  },
  {
    no: 'licenseImport.label.csvDNo',
    name: 'licenseImport.label.csvDName',
    required: true,
    description: '',
  },
  {
    no: 'licenseImport.label.csvENo',
    name: 'licenseImport.label.csvEName',
    required: true,
    description: '',
  },
  {
    no: 'licenseImport.label.csvFNo',
    name: 'licenseImport.label.csvFName',
    required: true,
    description: 'licenseImport.label.csvFDescription',
  },
];
