// price license
export const PRICE_LICENSE = 8800;

export const PRICE_LICENSE_MONTH = [
  731,
  1463,
  2194,
  2926,
  3657,
  4389,
  5120,
  5852,
  6583,
  7315,
  8046,
  8800,
];
