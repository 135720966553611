import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  MailRegister: string;
  UserName: string;
};

export type Column = TableColumn & {
  id:
    | 'infomationPurchaseId'
    | 'quantity'
    | 'totalAmount'
    | 'startTimeLicense'
    | 'department'
    | 'userName'
    | 'mailRegister'
    | 'mailLicense';
};

export const columns: Column[] = [
  {
    id: 'infomationPurchaseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'department',
    label: 'common.label.department',
    align: 'center',
  },
  {
    id: 'userName',
    label: 'common.label.username',
    align: 'center',
  },
  {
    id: 'mailRegister',
    label: 'common.label.email',
    align: 'center',
  },
  {
    id: 'startTimeLicense',
    label: 'common.label.startDate',
    align: 'center',
  },
  {
    id: 'quantity',
    label: 'common.label.quantity',
    align: 'center',
  },
  {
    id: 'totalAmount',
    label: 'TotalAmount',
    align: 'center',
  },
  {
    id: 'mailLicense',
    label: 'common.label.checkbox',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  MailRegister: string;
  UserName: string;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  MailRegister: '',
  UserName: '',
  pageIndex: DEFAULT_PAGE,
};
