import * as yup from 'yup';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  loginId: string;
  email: string;
  username: string;
  furigana: string;
  password: string;
  nickname: string;
  postcode: string;
  address: string;
  phoneNumber: string;
  profession: string;
  workplace: string;
  groupId: string | null;
  roleId: string;
  comment: string | null;
  countryId: string;
  isInvalid: boolean;
};

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const schemaEdit = yup.object({
  // loginId: yup
  //   .string()
  //   .required(t('muserCreate.msg.requiredUserid'))
  //   .matches(/^[a-z0-9_]+$/i, t('common.msg.format.userid'))
  //   .max(100, t('muserCreate.msg.requiredUserid')),
  email: yup.string()
    .required('userCreate.msg.requiredEmail')
    .email('userCreate.msg.requiredEmail')
    .max(256, 'userCreate.msg.requiredEmail'),
  furigana: yup
    .string()
    .trim()
    .matches(/^[\u30A0-\u30FF\s]+$/, 'purchase.msg.matchedKatakana')
    .required('purchase.msg.requiredBusinessOwnerNameFurigana')
    .max(256, 'purchase.msg.requiredBusinessOwnerNameFurigana'),
  nickname: yup.string()
    .required('userCreate.msg.nickname')
    .max(256, 'userCreate.msg.nickname'),
  countryId: yup.string()
    .required('groupCreate.msg.requiredCountryId')
    .typeError(('groupCreate.msg.requiredCountryId')),
  username: yup.string()
    .required(('userCreate.msg.requiredUsername'))
    .max(256, ('userCreate.msg.requiredUsername')),
  // postcode: yup.string().required('groupCreate.msg.requiredPostcode').max(50, 'groupCreate.msg.requiredPostcode'),
  // furigana: yup
  //   .string()
  //   .trim()
  //   .matches(/^[\u30A0-\u30FF\s]+$/, 'purchase.msg.matchedKatakana')
  //   .required('purchase.msg.requiredBusinessOwnerNameFurigana')
  //   .max(256, 'purchase.msg.requiredBusinessOwnerNameFurigana'),
  // address: yup.string().trim().required('purchase.msg.requiredAddress').max(256, 'purchase.msg.requiredAddress'),
  // phoneNumber: yup
  //   .string()
  //   .trim()
  //   .required('purchase.msg.requiredTelephoneNumber')
  //   .matches(phoneRegExp, 'purchase.msg.wrongTelephoneNumber'),
  // profession: yup
  //   .string()
  //   .trim()
  //   .required('purchase.msg.requiredProfession')
  //   .max(256, 'purchase.msg.requiredProfession'),
  // workplace: yup.string().trim().required('purchase.msg.requiredWorkplace').max(256, 'purchase.msg.requiredWorkplace'),
  // comment: yup.string().trim().notRequired().max(1024, 'purchase.msg.maxComment'),
});

// export const schemaNew = schemaEdit.concat(
//   yup.object({
//     password: yup
//       .string()
//       .required(('muserCreate.msg.requiredPassword'))
//       .min(6, ('Password107'))
//       .max(127, ('muserCreate.msg.requiredPassword'))
//       .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].*$/, ('Password107')),
//   })
// );

export type Column = TableColumn & {
  id:
    | 'userId'
    | 'loginId'
    | 'username'
    | 'groupNameWithCode'
    | 'countryId'
    | 'email'
    | 'roleId'
    | 'nickname'
    | 'isInvalid';
};

export const columns: Column[] = [
  {
    id: 'userId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
  },
  {
    id: 'username',
    label: 'common.label.username',
  },
  {
    id: 'email',
    label: 'common.label.email',
  },
  {
    id: 'roleId',
    label: 'common.label.role',
  },
  {
    id: 'countryId',
    label: 'common.label.countryTitle',
  },
  {
    id: 'nickname',
    label: 'common.label.nickname',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  loginId: string;
  groupId: string | null;
  roleId: string;
  countryId: string;
  username: string;
  email: string;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  loginId: '',
  groupId: null,
  roleId: '0',
  countryId: '',
  username: '',
  email: '',
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};
