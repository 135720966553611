import { Container, Typography, FormControl, MenuItem, TextField } from '@mui/material';
import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TGGrid from '../Elements/TGGrid';
import Logo from '../../container/layout/main/header/logo';
import MainCard from '../maincard';

type Props = {
  children: React.ReactNode;
  className?: string;
  classId?: string;
  enablePadding?: boolean;
};

function PurchaseForm({ children, className, enablePadding, classId }: Props) {
  const { t, i18n } = useTranslation();
  let locate = sessionStorage.getItem('locale') ?? 'ja';
  if (locate !== 'ja' && locate !== 'en') {
    locate = 'ja';
    sessionStorage.setItem('locale', locate);
    localStorage.setItem('locale', locate);
    i18n.changeLanguage(locate);
  }
  const [valueLocate, setValueLocate] = useState<string | null>(locate);
  const changeLocate = (event: ChangeEvent<HTMLInputElement>) => {
    setValueLocate(event.target.value);
    sessionStorage.setItem('locale', event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  const locates = [
    {
      name: t('login.lable.japanese'),
      value: 'ja'
    },
    {
      name: t('login.lable.english'),
      value: 'en',
    }
  ];
  return (
    <Container disableGutters maxWidth="xl" component="main" className={enablePadding ? 'purchase__padding_10' : ''} id={classId}>
      <MainCard sx={{ px: 5, pt: 3 }} className={className}>
        <TGGrid container columns={12}>
          <TGGrid item sm={8} xs={6} display="flex" alignItems="center">
            <Logo />
          </TGGrid>
          <TGGrid item sm={2} xs={3} display="flex" justifyContent="flex-end" alignItems="center" paddingRight="15px">
            <Typography className="font-NotoSansJP">
              {t('login.lable.locate')}
            </Typography>
          </TGGrid>
          <TGGrid item sm={2} xs={3}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <TextField
                select
                fullWidth
                // error={'locate' in errors}
                // helperText={errors.locate?.message}
                onChange={changeLocate}
                value={valueLocate}
              >
                {
                  locates.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                  ))
                }
              </TextField>
            </FormControl>
          </TGGrid>
        </TGGrid>
        {children}
      </MainCard>
    </Container>
  );
}

PurchaseForm.defaultProps = {
  className: '',
  classId: '',
  enablePadding: false,
};

export default PurchaseForm;
