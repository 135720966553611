import { call, put, takeLatest } from 'redux-saga/effects';
import UserinfoReadMessageServices from '../../services/userinfo_read_message';
import { types, searchUserInfoReadMessageSuccess } from '../actions/userinfo_read_message';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

interface Iaction {
  type: string,
  payload: any,
}

function* getUserInfoReadMessage(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(UserinfoReadMessageServices.getUMe, action.payload);
    yield put(searchUserInfoReadMessageSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}
export default function* userinfoSaga() {
  yield takeLatest(types.GET_USERINFO_READ_MESSAGE, sagaPromise(getUserInfoReadMessage));
}
