export const types = {
  CREATE_VERSION: 'CREATE_VERSION',
  CREATE_VERSION_SUCCESS: 'CREATE_VERSION_SUCCESS',
  EDIT_VERSION: 'EDIT_VERSION',
  EDIT_VERSION_SUCCESS: 'EDIT_VERSION_SUCCESS',
  SEARCH_VERSIONS: 'SEARCH_VERSIONS',
  SEARCH_USERINFO_READ_MESSAGE_SUCCESS: 'SEARCH_VERSIONS_SUCCESS',
  VERSION_DETAIL: 'VERSION_DETAIL',
  VERSION_DETAIL_SUCCESS: 'VERSION_DETAIL_SUCCESS',
  GET_USERINFO_READ_MESSAGE: 'GET_USERINFO_READ_MESSAGE'
};
export const createVersion = (payload: any) => ({
  type: types.CREATE_VERSION,
  payload
});

export const createVersionSuccess = (payload: any) => ({
  type: types.CREATE_VERSION_SUCCESS,
  payload
});

export const editVersion = (payload: any) => ({
  type: types.EDIT_VERSION,
  payload
});

export const editVersionSuccess = (payload: any) => ({
  type: types.EDIT_VERSION_SUCCESS,
  payload
});

export const searchVersions = (payload: any) => ({
  type: types.SEARCH_VERSIONS,
  payload
});

export const searchUserInfoReadMessageSuccess = (payload: any) => ({
  type: types.SEARCH_USERINFO_READ_MESSAGE_SUCCESS,
  payload
});

export const versionDetail = (payload: any) => ({
  type: types.VERSION_DETAIL,
  payload
});

export const versionDetailSuccess = (payload: any) => ({
  type: types.VERSION_DETAIL_SUCCESS,
  payload
});

export const getSUserInfoReadMessage = (payload: any) => ({
  type: types.GET_USERINFO_READ_MESSAGE,
  payload
});
