import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../constants/routes';
import PurchaseForm from '../component/Layout/PurchaseForm';
import TGGrid from '../component/Elements/TGGrid';

function PageNotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(routes.login);
    }, 10000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <TGGrid container columns={12} mt={8} ml={4}>
          <TGGrid item xs={12}>
            <Typography variant="h3" align="center">{t('titleExpiredOrNotExitPage')}</Typography>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
    </div>
  );
}

export default PageNotFound;
